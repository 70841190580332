// Lib
import { forwardRef, useState } from "react";
import { ControllerFieldState } from "react-hook-form";
import cn from "classnames";
import {
  Input as AntDInput,
  InputProps as AntInputProps,
  InputRef,
} from "antd";

import style from "./style.module.scss";
import { ErrorIcon, SuccessIcon } from "icons";

interface InputProps extends AntInputProps {
  success?: boolean;
  required?: boolean;
  isDisabled?: boolean;
  label?: string;
  fieldState?: ControllerFieldState;
  fieldError?: boolean | string;
}

export const Input = forwardRef<InputRef, InputProps>(
  (
    {
      required,
      label,
      isDisabled,
      fieldError,
      fieldState,
      success,
      onBlur,
      ...props
    },
    ref,
  ) => {
    const error = fieldError || fieldState?.error?.message || false;

    const [focus, setFocus] = useState(false);

    const getSuffixIcon = () => {
      if (focus) {
        return null;
      }
      if (error) {
        return <ErrorIcon />;
      }

      if (success) {
        return <SuccessIcon />;
      }
      return null;
    };

    const suffix = getSuffixIcon();

    return (
      <div style={{ width: "100%", position: "relative" }}>
        <div
          className={cn(style.root, {
            [style.focused]: focus,
            [style.success]: success,
            [style.error]: error,
            [style.disabled]: isDisabled,
          })}
        >
          <AntDInput
            ref={ref}
            disabled={isDisabled}
            {...props}
            onFocus={() => setFocus(true)}
            onBlur={e => {
              setFocus(false);
              onBlur && onBlur(e);
            }}
          />
        </div>

        <div className={style.suffix}>{suffix}</div>

        {typeof error === "string" && (
          <p className={style.errorMessage}>{error}</p>
        )}
      </div>
    );
  },
);

Input.displayName = "Input";
