// Lib
import { TablePaginationConfig } from "antd";
// Api
import { useSessionsQuery } from "api/hooks/sessions";
// Hooks
import { useTable } from "hooks";
// Types
import { FilterValue, SorterResult } from "antd/es/table/interface";
import { SessionResponse } from "types/sessions";
// Components
import { Pagination, Table } from "components";

import style from "./style.module.scss";

import { columns } from "./config";
import { IBasePaginatedQuery } from "types/common";
import { useEffect, useState } from "react";

export const Sessions = () => {
  const { page, limit, sortBy, sort, setPage, setLimit, handleSort } =
    useTable();

  const { data, isFetching } = useSessionsQuery({
    query: { page, limit, sortBy, sort },
  });

  const [tableData, setTableData] = useState<
    IBasePaginatedQuery<SessionResponse[]> | undefined
  >(undefined);

  useEffect(() => {
    if (!data) return;

    setTableData(data);
  }, [data]);

  const handleTableChange = (
    _pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue | null>,
    sorter: SorterResult<SessionResponse> | SorterResult<SessionResponse>[],
  ) => {
    const sort = sorter as SorterResult<SessionResponse>;
    handleSort(sort.columnKey, sort.order);
  };

  return (
    <>
      <h1 className={style.heading}>Sessions</h1>

      <div className={style.tableWrapper}>
        <Table<SessionResponse>
          loading={isFetching}
          columns={columns}
          dataSource={tableData?.data}
          totalCount={tableData?.meta?.total}
          totalCountPlaceholder="sessions"
          empty={{ title: "No Sessions found" }}
          limit={limit}
          setLimit={setLimit}
          onChange={handleTableChange}
        />
      </div>

      <Pagination
        page={page}
        setPage={setPage}
        totalItems={tableData?.meta?.total}
        limit={limit}
      />
    </>
  );
};
