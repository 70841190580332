import { createContext, PropsWithChildren, useState } from "react";
import { token } from "ulits/handleToken";

interface IAuthContext {
  isLoggedIn: boolean;
  handleLogin: (bearer: string, rememberMe: boolean) => void;
  handleLogout: () => void;
}

export const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export const AuthContextProvider = ({ children }: PropsWithChildren) => {
  const [isAuthorized, setIsAuthorized] = useState<string | undefined>(
    () => token.access.get()?.token,
  );

  const handleLogin = (bearer: string, rememberMe: boolean) => {
    token.access.set(bearer, rememberMe ? "localStorage" : "sessionStorage");
    setIsAuthorized(bearer);
  };

  const handleLogout = () => {
    token.clear();
    setIsAuthorized(undefined);
  };

  const isLoggedIn = !!isAuthorized;

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        handleLogin,
        handleLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
