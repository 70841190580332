// Lib
import { Table as AntDTable, TableProps as AntTableProps } from "antd";
import cn from "classnames";
// Types
import { ColumnGroupType, ColumnType } from "antd/es/table";
// Constants
import { DEFAULT_PAGE_SIZE, DEFAULT_TABLE_PAGINATION } from "consts/config";
// Icons
import { EyeIcon } from "icons";
// Components
import { Select } from "components/Select";
import { NoItemsContent } from "components/NoItemsContent";

import style from "./style.module.scss";

type EmptyMessage = {
  title: string;
  description?: string;
};

export type Column<RecordType> =
  | ColumnGroupType<RecordType>
  | ColumnType<RecordType>;

type TableProps<RecordType> = AntTableProps<RecordType> & {
  empty?: EmptyMessage;
  totalCount?: number;
  totalCountPlaceholder?: string;
  limit: number;
  setLimit: (arg: number) => void;
  onRowClick?: (record: RecordType) => void;
};

export const Table = <
  RecordType extends Partial<Record<keyof RecordType, unknown>>,
>({
  rowKey = "id",
  totalCount,
  totalCountPlaceholder = "items",
  limit = DEFAULT_PAGE_SIZE,
  empty,
  setLimit,
  onRowClick,
  ...rest
}: TableProps<RecordType>) => {
  const limitOptions = DEFAULT_TABLE_PAGINATION.pageSizeOptions.map(value => ({
    value,
    label: `${value} records`,
  }));

  const locale = empty && {
    emptyText: (
      <div>
        <NoItemsContent message={empty.title} description={empty.description} />
      </div>
    ),
  };

  const handleChange = (value: number) => {
    setLimit(value);
  };

  return (
    <div>
      <div className={style.heading}>
        {totalCount !== undefined ? (
          <p className={style.total}>
            {totalCount} {totalCountPlaceholder}
          </p>
        ) : (
          <div />
        )}

        <Select
          LabelIcon={<EyeIcon />}
          value={limit}
          options={limitOptions}
          onChange={handleChange}
        />
      </div>

      <div className={style.wrapper}>
        <AntDTable<RecordType>
          className={cn(style.root, { [style.clickable]: !!onRowClick })}
          rowKey={rowKey}
          onRow={record => ({
            onClick: () => {
              onRowClick ? onRowClick(record) : null;
            },
          })}
          pagination={false}
          locale={locale}
          {...rest}
        />
      </div>
    </div>
  );
};
