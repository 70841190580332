import { FC } from "react";
import { ResetScaleIcon } from "icons/ResetScaleIcon";

import style from "./style.module.scss";
import { ZoomControlsProps } from "./typed";

export const ZoomControls: FC<ZoomControlsProps> = ({
  scale,
  onZoomIn,
  onZoomOut,
  onResetZoom,
}) => {
  const handleReset = () => {
    onResetZoom();
  };
  return (
    <div className={style.zoomBox}>
      <div className={style.buttons}>
        <span className={style.btn} onClick={onZoomOut}>
          -
        </span>
        <span style={{ color: "white" }}>{scale}%</span>
        <span className={style.btn} onClick={onZoomIn}>
          +
        </span>
      </div>
      <ResetScaleIcon onClick={handleReset} />
    </div>
  );
};
