import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/global.scss";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { AuthContextProvider, NotificationProvider } from "context";
import { createQueryClient } from "api/config";
import { QueryClientProvider } from "@tanstack/react-query";

const queryClient = createQueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <AuthContextProvider>
      <NotificationProvider>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </QueryClientProvider>
      </NotificationProvider>
    </AuthContextProvider>
  </React.StrictMode>,
);
