import { SVGProps } from "react";

export const EmailIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.00098 15.2393C2.08105 15.2393 1.38965 15.0107 0.926758 14.5537C0.469727 14.1025 0.241211 13.4229 0.241211 12.5146V2.81152C0.241211 1.90918 0.458008 1.23242 0.891602 0.78125C1.33105 0.324219 1.94629 0.0957031 2.7373 0.0957031H16.8877C17.8076 0.0957031 18.4961 0.324219 18.9531 0.78125C19.416 1.23242 19.6475 1.90918 19.6475 2.81152V12.5146C19.6475 13.4229 19.4277 14.1025 18.9883 14.5537C18.5547 15.0107 17.9424 15.2393 17.1514 15.2393H3.00098ZM2.94824 13.8945H16.9404C17.374 13.8945 17.708 13.7803 17.9424 13.5518C18.1826 13.3174 18.3027 12.9746 18.3027 12.5234V2.82031C18.3027 2.36328 18.1826 2.02051 17.9424 1.79199C17.708 1.55762 17.374 1.44043 16.9404 1.44043H2.94824C2.50879 1.44043 2.17188 1.55762 1.9375 1.79199C1.70312 2.02051 1.58594 2.36035 1.58594 2.81152V12.5146C1.58594 12.9717 1.70312 13.3174 1.9375 13.5518C2.17188 13.7803 2.50879 13.8945 2.94824 13.8945ZM9.96191 10.0098C9.65137 10.0098 9.34375 9.94238 9.03906 9.80762C8.74023 9.67285 8.43848 9.45312 8.13379 9.14844L0.953125 2.09961L1.87598 1.17676L8.89844 8.11133C9.08594 8.29297 9.26465 8.43066 9.43457 8.52441C9.61035 8.6123 9.78613 8.65625 9.96191 8.65625C10.1318 8.65625 10.3018 8.6123 10.4717 8.52441C10.6475 8.43652 10.832 8.29883 11.0254 8.11133L18.0127 1.21191L18.9268 2.13477L11.79 9.14844C11.4795 9.45312 11.1748 9.67285 10.876 9.80762C10.5771 9.94238 10.2725 10.0098 9.96191 10.0098ZM17.9248 14.0879L12.2383 8.46289L13.1523 7.54883L18.8389 13.165L17.9248 14.0879ZM1.07617 13.1738L6.7627 7.54883L7.68555 8.46289L1.99023 14.0967L1.07617 13.1738Z"
        fill="#BABABA"
      />
    </svg>
  );
};
