// Lib
import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
// Hooks
import { useNotification } from "hooks";
// Types
import {
  IBaseQuery,
  IBasePaginatedQuery,
  UrlQueryParams,
  BaseError,
} from "types/common";
import { LogDataResponse } from "types/logs";
import { TaskResponse } from "types/task";
// Constants
import { endpoints } from "api/endpoints";
// Utils
import { axiosInstance } from "ulits/axios";
import { generateQueryUrl } from "ulits/generateQueryUrl";
import { errorHandler } from "ulits/errorHandler";

type LogsQuery = {
  query: UrlQueryParams;
};

export const useLogsQuery = ({ query }: LogsQuery) => {
  const { openNotification } = useNotification();

  const data = useQuery<IBasePaginatedQuery<LogDataResponse[]>, BaseError>({
    queryKey: [generateQueryUrl(endpoints.logs, query)],
    queryFn: () => axiosInstance.get(generateQueryUrl(endpoints.logs, query)),
  });

  useEffect(() => {
    if (data?.isError) {
      openNotification({ message: errorHandler(data?.error), type: "error" });
    }
  }, [data]);

  return data;
};

type TaskRequest = {
  id?: string;
};

export const useLogDetailsQuery = ({ id }: TaskRequest) => {
  const { openNotification } = useNotification();

  const baseUrl = `${endpoints.logs}/${id}`;

  const data = useQuery<IBaseQuery<TaskResponse>, BaseError>({
    queryKey: [baseUrl],
    queryFn: () => axiosInstance.get(baseUrl),
    enabled: !!id,
  });

  useEffect(() => {
    if (data?.isError) {
      openNotification({ message: errorHandler(data?.error), type: "error" });
    }
  }, [data]);

  return data;
};
