// Lib
import { FC } from "react";
import { Pagination as AntDPagination } from "antd";
// Icons
import { NextIcon, PrevIcon } from "icons";

import style from "./style.module.scss";

interface PaginationProps {
  page: number;
  limit: number;
  setPage: (arg: number) => void;
  totalItems?: number;
}

export const Pagination: FC<PaginationProps> = ({
  page,
  limit,
  totalItems,
  setPage,
}) => {
  const onPageChange = (page: number) => {
    setPage(page);
  };

  return (
    <div className={style.root}>
      <AntDPagination
        className={style.pagination}
        current={page}
        pageSize={limit}
        onChange={onPageChange}
        showSizeChanger={false}
        total={totalItems}
        prevIcon={<PrevIcon />}
        nextIcon={<NextIcon />}
      />
    </div>
  );
};
