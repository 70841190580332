import { Dispatch, SetStateAction } from "react";
import { TaskResponse } from "../../types/task";
import { Point } from "../../types/shapes";

export enum ToolType {
  Edit = "edit",
  Point = "point",
  Polygon = "polygon",
  BoundingBox = "box",
}
export interface AnnotateToolProps {
  imageUrl: string;
  data: TaskResponse | null;
  setActiveTool?: Dispatch<SetStateAction<ToolType | null | undefined>>;
  activeTool?: ToolType | null | undefined;
  shapes: Shape[];
  setShapes: Dispatch<SetStateAction<Shape[]>>;
  admin: boolean;
}

export type Shape = {
  shape: ToolType;
  isFinished?: boolean;
  points?: Point[];
  left?: number;
  top?: number;
  width?: number;
  height?: number;
};
