import { Status, Type } from "components";
import { Column } from "components/Table";

import { SessionResponse, SessionStatus, SessionType } from "types/sessions";

import { dateTransform, durationTransform } from "ulits/dateTransform";

export const columns: Column<SessionResponse>[] = [
  {
    title: "Date",
    dataIndex: "createdAt",
    key: "createdAt",
    sorter: true,
    render: date => dateTransform({ date }),
  },
  {
    title: "IP address",
    dataIndex: "client",
    key: "ip",
    sorter: true,
    render: client => client?.clientPublicIp || client?.clientIp,
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    sorter: true,
    render: (type: SessionType) => <Type status={type} />,
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    sorter: true,
    render: (status: SessionStatus) => <Status status={status} />,
  },
  {
    title: "Sessions duration",
    dataIndex: "duration",
    key: "duration",
    sorter: true,
    render: (duration: number) => durationTransform(duration),
  },
];
