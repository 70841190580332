// Lib
import { FC } from "react";
import cn from "classnames";
// Types

import style from "./style.module.scss";
import { SessionStatus } from "types/sessions";

interface StatusProps {
  status: SessionStatus;
}

export const Status: FC<StatusProps> = ({ status }) => {
  const title = {
    [SessionStatus.ACTIVE]: "Active",
    [SessionStatus.ENDED]: "Ended",
  };

  return (
    <div className={cn(style.root, style[status])}>
      <span className={style.status}>{title[status]}</span>
    </div>
  );
};
