// Lib
import { FC, ReactNode, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import cn from "classnames";
// Hooks
import { useAuth } from "hooks";
// Types
import { SidebarMenuItemType } from "types/common";
// Constants
import { ADMIN_SIDEBAR_MENU_ITEMS } from "consts";
// Icons
import { Logo, LogoutIcon } from "icons";

import style from "./style.module.scss";

interface AdminLayoutProps {
  children: ReactNode;
}

type NavigationType = {
  base: SidebarMenuItemType[];
  additional: SidebarMenuItemType[];
};

export const AdminLayout: FC<AdminLayoutProps> = ({ children }) => {
  const { handleLogout } = useAuth();
  const { pathname } = useLocation();

  //scroll to top after change route
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const navigation: NavigationType = ADMIN_SIDEBAR_MENU_ITEMS.admin.reduce(
    (acc: NavigationType, item) => {
      switch (item.type) {
        case "additional":
          acc.additional.push(item);
          break;
        case "base":
          acc.base.push(item);
          break;

        default:
          break;
      }

      return acc;
    },
    {
      base: [],
      additional: [],
    },
  );

  return (
    <div className={style.root}>
      <nav className={style.sidebar}>
        <div className={style.sidebarMenu}>
          <Logo className={style.sidebarLogo} />

          {!!navigation?.base?.length && (
            <>
              {navigation?.base.map(({ label, key, path, Icon }) => (
                <NavLink
                  key={key}
                  className={cn(style.navLink, {
                    [style.activeNavLink]: pathname
                      .toLocaleLowerCase()
                      .includes(key),
                  })}
                  to={path}
                >
                  <div className={style.iconWrapper}>
                    <Icon />
                  </div>
                  {label}
                </NavLink>
              ))}
              <div className={style.devider} />
            </>
          )}

          {!!navigation?.additional?.length &&
            navigation?.additional.map(({ label, key, path, Icon }) => (
              <Link
                key={key}
                className={cn(style.navLink, {
                  [style.activeNavLink]: pathname
                    .toLocaleLowerCase()
                    .includes(key),
                })}
                to={path}
              >
                <div className={style.iconWrapper}>
                  <Icon />
                </div>
                {label}
              </Link>
            ))}
        </div>

        <button className={cn(style.navLink)} onClick={handleLogout}>
          <div className={style.iconWrapper}>
            <LogoutIcon />
          </div>
          Logout
        </button>
      </nav>
      <div className={style.pageContainer}>{children}</div>
    </div>
  );
};
