// Lib
import { FC } from "react";

import style from "./style.module.scss";

interface TableImageProps {
  url: string;
  name?: string;
}

export const TableImage: FC<TableImageProps> = ({ url, name = "" }) => {
  return (
    <div className={style.imageContainer}>
      <div className={style.imageWrapper}>
        <img
          className={style.image}
          src={url}
          height={40}
          width={40}
          alt="image"
        />
      </div>

      <p className={style.imageName}>{name}</p>
    </div>
  );
};
