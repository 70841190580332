import { useEffect } from "react";
import { AxiosResponse } from "axios";
import { useAuth } from "hooks";
import { axiosInstance } from "ulits/axios";

const UNAUTHORIZED = 401;

export const AxiosWatcher = () => {
  const { handleLogout } = useAuth();

  useEffect(() => {
    const responseId = axiosInstance.interceptors.response.use(
      (response: AxiosResponse) => {
        return response.data;
      },
      async error => {
        const {
          response: { status },
        } = error;

        if (status === UNAUTHORIZED) {
          handleLogout();
        }
        return Promise.reject(error);
      },
    );

    return () => {
      axiosInstance.interceptors.response.eject(responseId);
    };
  }, [handleLogout]);

  return null;
};
