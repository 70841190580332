import { Dispatch, SetStateAction, useEffect } from "react";
import { ToolType } from "components/AnnotateTool/typed";

interface HelperFunctions {
  setActiveTool?: Dispatch<SetStateAction<ToolType | null | undefined>>;
  setSelectedBoxIndex: Dispatch<SetStateAction<number | null>>;
  admin: boolean;
}
enum Key {
  Q = "KeyQ",
  E = "KeyE",
  W = "KeyW",
  R = "KeyR",
}
export const useKeyPressEffect = ({
  setActiveTool,
  setSelectedBoxIndex,
  admin,
}: HelperFunctions) => {
  useEffect(() => {
    if (admin) return;
    const keyPressHandler = (e: KeyboardEvent) => {
      if (setActiveTool)
        switch (e.code) {
          case Key.Q:
            setActiveTool(ToolType.Edit);
            break;
          case Key.E:
            setActiveTool(ToolType.BoundingBox);
            setSelectedBoxIndex(null);
            break;
          case Key.W:
            setActiveTool(ToolType.Point);
            break;
          case Key.R:
            setActiveTool(ToolType.Polygon);
            break;
          default:
            break;
        }
    };

    window.addEventListener("keydown", keyPressHandler);

    return () => {
      window.removeEventListener("keydown", keyPressHandler);
    };
  }, [setActiveTool, setSelectedBoxIndex]);
};
