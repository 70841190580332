// Lib
import { useState, useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
// Api
import {
  useChangePasswordMutation,
  useCheckPasswordMutation,
} from "api/hooks/auth";
// Hooks
import { useAuth, useDebounce } from "hooks";
// Icons
import { LogoutIcon } from "icons";
// Components
import { ConfirmDialog } from "components/Modals";
import { Button, Input } from "components/Form";

import style from "./style.module.scss";

import { checkResolver, resolver } from "./validation";

const DEFAULT_VALUES = {
  password: "",
  newPassword: "",
};

const DEFAULT_CHECK_VALUES = {
  checkPassword: "",
};

type ChangePasswordFormTypes = {
  password: string;
  newPassword: string;
};

type CheckPasswordFormTypes = {
  checkPassword: string;
};

export const Profile = () => {
  const { handleLogout } = useAuth();

  const [successModal, setSuccessModal] = useState(false);
  const [currentPassword, setCurrentPassword] = useState<string>("");

  const debouncedPassword = useDebounce(currentPassword, 1500);

  const {
    mutate: checkPassword,
    data: passwordCheckData,
    reset: resetCheckPassword,
  } = useCheckPasswordMutation();

  useEffect(() => {
    if (!debouncedPassword) return;

    checkPassword({ password: debouncedPassword });
  }, [debouncedPassword]);

  const {
    mutate: changePassword,
    isPending: isChangePasswordLoading,
    isSuccess: isChangePasswordSuccess,
  } = useChangePasswordMutation();

  useEffect(() => {
    if (!isChangePasswordSuccess) {
      return;
    }
    setSuccessModal(true);
  }, [isChangePasswordSuccess]);

  const {
    handleSubmit,
    control,
    formState: { isValid },
    reset,
  } = useForm<ChangePasswordFormTypes>({
    mode: "onChange",
    defaultValues: DEFAULT_VALUES,
    resolver,
  });

  const onSubmit: SubmitHandler<ChangePasswordFormTypes> = async data => {
    if (!passwordCheckData?.data?.valid) {
      return;
    }
    changePassword({
      password: currentPassword,
      newPassword: data.newPassword,
    });
  };

  const {
    handleSubmit: handleSubmitCheck,
    control: controlCheck,
    formState: { isValid: isValidCheck },
    watch: checkWatch,
    reset: resetCheck,
  } = useForm<CheckPasswordFormTypes>({
    mode: "onChange",
    shouldFocusError: false,
    defaultValues: DEFAULT_CHECK_VALUES,
    resolver: checkResolver,
  });

  const currentPasswordField = checkWatch("checkPassword");

  const onSubmitCheck: SubmitHandler<CheckPasswordFormTypes> = async data => {
    setCurrentPassword(data.checkPassword);
  };

  useEffect(() => {
    if (currentPasswordField) {
      handleSubmitCheck(onSubmitCheck)();
    }
  }, [currentPasswordField]);

  const isCheckError =
    passwordCheckData?.data?.valid === false
      ? "Passwords don’t match. Please check and try again"
      : undefined;

  const handleCancel = async () => {
    setCurrentPassword("");
    resetCheckPassword();
    reset(DEFAULT_VALUES);
    resetCheck(DEFAULT_CHECK_VALUES);
  };

  const handleAprove = () => {
    handleCancel();
    setSuccessModal(false);
  };

  return (
    <div className={style.pageWrapper}>
      <div>
        <h1 className={style.heading}>Profile</h1>

        <p className={style.title}>Change password</p>

        <p className={style.description}>
          Please enter your current password to change your password
        </p>
        <div className={style.formСontainer}>
          <div className={style.inputContainer}>
            <span className={style.label}>Current Password</span>
            <form className={style.inputWrapper}>
              <Controller
                name={"checkPassword"}
                control={controlCheck}
                render={({ field, fieldState }) => (
                  <Input
                    type="password"
                    isDisabled={isChangePasswordLoading}
                    {...field}
                    fieldState={fieldState}
                    success={passwordCheckData?.data.valid}
                    fieldError={isCheckError}
                  />
                )}
              />
            </form>
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={style.inputContainer}>
              <span className={style.label}>New Password</span>

              <div className={style.inputWrapper}>
                <Controller
                  name={"password"}
                  control={control}
                  render={({ field, fieldState }) => (
                    <Input
                      type="password"
                      isDisabled={isChangePasswordLoading}
                      {...field}
                      fieldState={fieldState}
                      success={fieldState.isDirty && !fieldState.error}
                    />
                  )}
                />
              </div>
            </div>

            <div className={style.inputContainer}>
              <span className={style.label}>Confirm New Password</span>

              <div className={style.inputWrapper}>
                <Controller
                  name={"newPassword"}
                  control={control}
                  render={({ field, fieldState }) => (
                    <Input
                      type="password"
                      isDisabled={isChangePasswordLoading}
                      {...field}
                      fieldState={fieldState}
                      success={fieldState.isDirty && !fieldState.error}
                    />
                  )}
                />
              </div>
            </div>

            <div className={style.buttonsContainer}>
              <Button
                textClassName={style.cancelButton}
                type="secondary"
                size="48"
                text="Cancel"
                onClick={e => {
                  e.preventDefault();
                  handleCancel();
                }}
              />

              <Button
                type="primary"
                size="48"
                htmlType="submit"
                text="Update password"
                disabled={
                  !isValid ||
                  !passwordCheckData?.data?.valid ||
                  isChangePasswordLoading
                }
              />
            </div>
          </form>
        </div>
      </div>

      <Button
        type="logout"
        Icon={LogoutIcon}
        text="Logout"
        size="48"
        buttonClassName={style.logoutButton}
        textClassName={style.logoutButtonText}
        onClick={handleLogout}
      />

      <ConfirmDialog
        open={successModal}
        message="Password changed"
        description="You have successfully change the password"
        type="success"
        firstCTAButton={{
          text: "Done",
          fullwidth: true,
          size: "56",
          onClick: handleAprove,
        }}
      />
    </div>
  );
};
