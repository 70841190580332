// Lib
import { useEffect } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
// Hooks
import { useNotification } from "hooks";
// Types
import { BaseError, IBasePaginatedQuery, UrlQueryParams } from "types/common";
import { SessionResponse } from "types/sessions";
// Constants
import { endpoints } from "api/endpoints";
// Utils
import { axiosInstance } from "ulits/axios";
import { generateQueryUrl } from "ulits/generateQueryUrl";
import { errorHandler } from "ulits/errorHandler";

type SessionsQuery = {
  query: UrlQueryParams;
};

export const useSessionsQuery = ({ query }: SessionsQuery) => {
  const { openNotification } = useNotification();

  const data = useQuery<IBasePaginatedQuery<SessionResponse[]>, BaseError>({
    queryKey: [generateQueryUrl(endpoints.sessions, query)],
    queryFn: () =>
      axiosInstance.get(generateQueryUrl(endpoints.sessions, query)),
  });

  useEffect(() => {
    if (data?.isError) {
      openNotification({ message: errorHandler(data?.error), type: "error" });
    }
  }, [data]);

  return data;
};

export const useCreateSessionMutation = (location?: number | null) => {
  const { openNotification } = useNotification();
  const data = useMutation<any, BaseError, any>({
    mutationFn: data =>
      axiosInstance.post(
        endpoints.session,
        { data },
        {
          headers: {
            // "x-location-custom": location,
          },
        },
      ),
    onError: error =>
      openNotification({ message: errorHandler(error), type: "error" }),
  });

  return data;
};
