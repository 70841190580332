import { Dispatch, SetStateAction } from "react";
import Konva from "konva";
import { Shape, ToolType } from "../../components/AnnotateTool/typed";
import KonvaEventObject = Konva.KonvaEventObject;
import { useGetMousePos } from "./useGetMousePos";

export const handleCornerDragMove = (
  e: KonvaEventObject<DragEvent>,
  boxIndex: number,
  corner: string | null | undefined,
  boxes: Shape[],
  draggingCorner: string | null,
  startPoint: { x: number | undefined; y: number | undefined } | null,
  startSize: { width: number; height: number } | null,
  setBoxes: Dispatch<SetStateAction<Shape[]>>,
  setMousePosition: Dispatch<SetStateAction<{ x: number; y: number }>>,
) => {
  if (!draggingCorner || !startPoint || !startSize) return;

  const stage = e.target.getStage();
  if (!stage) return;

  const position = stage.getPointerPosition();
  if (position) {
    setMousePosition({ x: position.x, y: position.y });
  }
  if (!position || boxIndex === null) return;

  const [x, y] = useGetMousePos(stage);

  const newBoxes = [...boxes];
  const box = newBoxes[boxIndex];

  let newWidth = startSize.width;
  let newHeight = startSize.height;
  let newX = box.left;
  let newY = box.top;
  if (box.left && box.top && box.width && box.height)
    switch (draggingCorner) {
      case "topLeft":
        newWidth = box.left + box.width - x;
        newHeight = box.top + box.height - y;
        newX = x;
        newY = y;
        break;
      case "topRight":
        newWidth = x - box.left;
        newHeight = box.top + box.height - y;
        newY = y;
        break;
      case "bottomLeft":
        newWidth = box.left + box.width - x;
        newHeight = y - box.top;
        newX = x;
        break;
      case "bottomRight":
        newWidth = x - box.left;
        newHeight = y - box.top;
        break;
      default:
        return;
    }

  newBoxes[boxIndex] = {
    shape: ToolType.BoundingBox,
    left: newX,
    top: newY,
    width: newWidth,
    height: newHeight,
  };

  setBoxes(newBoxes);
};
