// Lib
import { FC, ReactNode } from "react";
// Types
import { Modal as AntDModal, ModalProps as AntDModalProps } from "antd";
// Icons

import style from "./style.module.scss";

interface ModalProps extends AntDModalProps {
  isLoading?: boolean;
  closeIcon?: boolean;
  width?: number;
  title?: string;
  subTitle?: string;
  suffix?: ReactNode;
  onClose?: () => void;
}

export const Modal: FC<ModalProps> = ({
  title,
  subTitle,
  width = 640,
  children,
  suffix,
  ...props
}) => {
  return (
    <AntDModal
      centered
      footer={null}
      width={width}
      closeIcon={null}
      {...props}
      className={style.root}
    >
      {children}
    </AntDModal>
  );
};
