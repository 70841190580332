// Lib
import { useMutation } from "@tanstack/react-query";
// Hooks
import { useAuth, useNotification } from "hooks";
// Types
import {
  AdminPasswordChangeRequest,
  AdminPasswordCheckRequest,
  AdminPasswordCheckResponse,
  AdminSignInRequest,
  AdminSingInResponse,
} from "types/auth";
import { BaseError, IBaseQuery } from "types/common";
// Constants
import { endpoints } from "api/endpoints";
// Utils
import { axiosInstance } from "ulits/axios";
import { errorHandler } from "ulits/errorHandler";

export const useLoginMutation = () => {
  const { handleLogin } = useAuth();

  const { openNotification } = useNotification();

  const data = useMutation<
    IBaseQuery<AdminSingInResponse>,
    BaseError,
    AdminSignInRequest
  >({
    mutationFn: ({ email, password }) =>
      axiosInstance.post(endpoints.signIn, {
        data: {
          email,
          password,
        },
      }),
    onSuccess: (response, input) => {
      //TODO: fix after BE Fixes
      handleLogin(
        // @ts-ignore
        response?.data?.accessToken || response?.accessToken,
        !!input?.rememberMe,
      );
    },
    onError: error => {
      const isPasswordError =
        error?.response?.data?.statusCode === 400 ||
        error?.response?.data?.statusCode === 404;

      if (!isPasswordError) {
        openNotification({ message: errorHandler(error), type: "error" });
      }
    },
  });

  return data;
};

export const useCheckPasswordMutation = () => {
  const { openNotification } = useNotification();

  const data = useMutation<
    IBaseQuery<AdminPasswordCheckResponse>,
    BaseError,
    AdminPasswordCheckRequest
  >({
    mutationFn: data => axiosInstance.post(endpoints.passwordCheck, { data }),
    onError: error => {
      const isPasswordError =
        error?.response?.data?.statusCode === 400 ||
        error?.response?.data?.statusCode === 404;

      console.log("isPasswordError", isPasswordError);
      if (!isPasswordError) {
        openNotification({ message: errorHandler(error), type: "error" });
      }
    },
  });

  return data;
};

export const useChangePasswordMutation = () => {
  const { openNotification } = useNotification();

  const data = useMutation<
    IBaseQuery<void>,
    BaseError,
    AdminPasswordChangeRequest
  >({
    mutationFn: data => axiosInstance.post(endpoints.passwordChange, { data }),
    onError: error =>
      openNotification({ message: errorHandler(error), type: "error" }),
  });

  return data;
};
