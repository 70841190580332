import Konva from "konva";
import { Dispatch, SetStateAction } from "react";
import KonvaEventObject = Konva.KonvaEventObject;
import { Shape, ToolType } from "../../components/AnnotateTool/typed";
import { useGetMousePos } from "./useGetMousePos";

export const handleStageMouseMove = (
  e: KonvaEventObject<MouseEvent>,
  tempBoundingBox: Shape | null,
  setTempBoundingBox: Dispatch<SetStateAction<Shape | null>>,
  setMousePosition: Dispatch<SetStateAction<{ x: number; y: number }>>,
  scale: number,
) => {
  const stage = e.target.getStage();
  if (stage) {
    const mousePos = useGetMousePos(stage);
    setMousePosition({ x: mousePos[0], y: mousePos[1] });

    if (tempBoundingBox && tempBoundingBox.left && tempBoundingBox.top) {
      const newBoundingBox: Shape = {
        shape: ToolType.BoundingBox,
        left: tempBoundingBox.left,
        top: tempBoundingBox.top,
        width: mousePos[0] - tempBoundingBox.left,
        height: mousePos[1] - tempBoundingBox.top,
      };
      setTempBoundingBox(newBoundingBox);
    }
  }
};
