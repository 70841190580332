// Lib
import { forwardRef } from "react";
import {
  Checkbox as AntDCheckbox,
  CheckboxProps as AntCheckboxProps,
  CheckboxRef,
} from "antd";

import style from "./style.module.scss";

interface CheckboxProps extends AntCheckboxProps {
  label?: string;
}

export const Checkbox = forwardRef<CheckboxRef, CheckboxProps>(
  ({ label, ...props }, ref) => {
    return (
      <div className={style.root}>
        <AntDCheckbox ref={ref} {...props}>
          {label}
        </AntDCheckbox>
      </div>
    );
  },
);

Checkbox.displayName = "Checkbox";
