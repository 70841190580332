// Lib
import { FC, ReactNode } from "react";
import { TooltipProps as AntDTooltipProps, Tooltip as AntDTooltip } from "antd";

import style from "./style.module.scss";

type ToolsButtonTooltipProps = {
  hotkey: string;
  title: string;
};

interface TooltipProps {
  children: ReactNode;
  userTool?: ToolsButtonTooltipProps;
}

export const Tooltip: FC<TooltipProps & AntDTooltipProps> = ({
  children,
  userTool,
  ...props
}) => {
  const isUserTool = !!userTool;

  const usersToolTitle = (): ReactNode => {
    if (!isUserTool) return <></>;

    return (
      <div className={style.usersToolContainer}>
        <span className={style.toolTitleText}>{userTool.title}</span>
        <div className={style.devider} />
        <span className={style.toolTitleHotkey}>{userTool.hotkey}</span>
      </div>
    );
  };
  return (
    <AntDTooltip
      placement={isUserTool ? "right" : undefined}
      title={isUserTool ? usersToolTitle() : undefined}
      overlayClassName={style.tooltip}
      {...props}
    >
      {children}
    </AntDTooltip>
  );
};
