// Lib
import { FC } from "react";
// Types
import { InstructionsDataType } from "types/task";
// Helpers
import { handleParseContent, handleMaxWidth } from "./helpers";
// Icons
import { InfoIcon } from "icons";
// Components
import { Tooltip } from "components";

import style from "./style.module.scss";

interface TaskBoxProps {
  task: InstructionsDataType | null;
  maxWidth?: number;
}

export const TaskBox: FC<TaskBoxProps> = ({ task, maxWidth }) => {
  const content =
    task && Object.entries(task).map(el => handleParseContent(el[0], el[1]));
  return (
    <div className={style.taskBox}>
      <Tooltip
        key="taks-tool-tip"
        overlayClassName={style.tooltip}
        placement="bottomLeft"
        title={<div className={style.tooltipTaskBody}>{content}</div>}
        style={{ maxWidth }}
        overlayStyle={{
          maxWidth: handleMaxWidth(maxWidth),
        }}
      >
        <div>
          <InfoIcon />
        </div>
      </Tooltip>

      <div className={style.taskText}>{task?.title?.description}</div>
    </div>
  );
};
