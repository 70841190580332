import { UrlQueryParams } from "types/common";

export const generateQueryUrl = (
  url: string,
  queryParams?: UrlQueryParams,
): string => {
  if (queryParams) {
    const queryString = Object.entries(queryParams)
      .map(([key, value]) => {
        return value
          ? `${key}=${encodeURIComponent(value.toString())}`
          : undefined;
      })
      .filter(Boolean)
      .join("&");

    return `${url}?${queryString}`;
  }

  return url;
};
