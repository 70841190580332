// Lib
import { FC, ReactNode } from "react";
import cn from "classnames";
// Types
import { ButtonProps } from "types/common";
// Icons
import { SuccessRoundedIcon, AlertIcon } from "icons";
// Layouts
// Components
import { Button } from "components/Form";
import { Modal } from "../Modal";
// Styled

import style from "./style.module.scss";

interface ConfirmDialogProps {
  type?: "success" | "alert";
  isLoading?: boolean;
  open: boolean;
  message: ReactNode;
  description?: ReactNode;
  firstCTAButton: ButtonProps;
  secondCTAButton?: ButtonProps;
}

export const ConfirmDialog: FC<ConfirmDialogProps> = ({
  open,
  type,
  message,
  description,
  firstCTAButton,
  secondCTAButton,
}) => {
  const icon = () => {
    switch (type) {
      case "success":
        return <SuccessRoundedIcon />;
      case "alert":
        return <AlertIcon />;

      default:
        return null;
    }
  };
  return (
    <Modal open={open} width={396}>
      {!!type && (
        <div
          className={cn(style.iconContainer, { [style[`icon-${type}`]]: type })}
        >
          {icon()}
        </div>
      )}

      <p className={style.message}>{message}</p>

      {!!description && <p className={style.description}>{description}</p>}

      <div
        className={cn(style.buttonsContainer, {
          [style.twoButtonsContainer]: !!firstCTAButton && !!secondCTAButton,
        })}
      >
        {!!firstCTAButton && <Button {...firstCTAButton} />}

        {!!secondCTAButton && <Button {...secondCTAButton} />}
      </div>
    </Modal>
  );
};
