// Lib
import { forwardRef, ReactNode, useState } from "react";
import cn from "classnames";
import {
  Select as AntDSelect,
  SelectProps as AntDSelectProps,
  RefSelectProps,
} from "antd";
// Icons
import { ArrowUpIcon } from "icons";

import style from "./style.module.scss";

export interface SelectProps extends AntDSelectProps {
  LabelIcon?: ReactNode;
}

export const Select = forwardRef<RefSelectProps, SelectProps>(
  ({ LabelIcon, options, ...props }, ref) => {
    const [opened, setOpened] = useState(false);

    return (
      <AntDSelect
        ref={ref}
        className={style.root}
        options={options}
        onDropdownVisibleChange={opened => setOpened(opened)}
        suffixIcon={
          <ArrowUpIcon
            className={cn(style.openCloseIcon, {
              [style.openCloseIconOpened]: opened,
            })}
          />
        }
        popupClassName={style.popup}
        labelRender={option => (
          <span className={style.label}>
            {LabelIcon && LabelIcon} {option.label}
          </span>
        )}
        {...props}
      />
    );
  },
);

Select.displayName = "Select";
