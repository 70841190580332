import { Dispatch, SetStateAction } from "react";
import { Shape, ToolType } from "components/AnnotateTool/typed";

export const useDeleteVertex = (
  index: number,
  shapes: Shape[],
  setShapes: Dispatch<SetStateAction<Shape[]>>,
  setSelectedBoxIndex: Dispatch<SetStateAction<number | null>>,
) => {
  const updatedShapes = [...shapes];
  const lastShapeIndex = updatedShapes?.length - 1;
  const lastShapePoint = updatedShapes[lastShapeIndex]?.points;
  const lastShapePointIndex = lastShapePoint && lastShapePoint?.length - 1;
  setShapes(prevShapes => {
    const shapes = [...prevShapes];
    if (index >= 0 && index < shapes.length) {
      const shape = shapes[index];
      const points = shapes[index].points;
      if (
        shape.shape === ToolType.Polygon &&
        shape &&
        points &&
        points.length >= 0
      ) {
        shape.points = shape.points?.slice(0, lastShapePointIndex);
        shape.isFinished = false;
      }
      if (points && points.length <= 0) {
        shapes.splice(index, 1);
        setSelectedBoxIndex(null);
      }
    }
    return shapes;
  });
};
