import { Dispatch, SetStateAction } from "react";
import { Shape, ToolType } from "components/AnnotateTool/typed";

export const useAutoFillPolygon = (
  activeTool: ToolType | null | undefined,
  setShapes: Dispatch<SetStateAction<Shape[]>>,
) => {
  if (activeTool === ToolType.Polygon) {
    setShapes(prevShapes => {
      return prevShapes.map(shape => {
        if (
          shape.shape === ToolType.Polygon &&
          shape?.points &&
          shape.points.length > 2 &&
          !shape.isFinished
        ) {
          return { ...shape, isFinished: true };
        }
        return shape;
      });
    });
  }
};
