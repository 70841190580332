// Lib
import { Navigate, Route, Routes } from "react-router-dom";
// Hooks
import { useAuth } from "hooks";
// Constants
import { ADMIN_ROUTES, LOGIN } from "consts";
// Components
import { Home } from "./pages/Home";
import { Login } from "./pages/Admin";
import { AuthRequired, AdminLayout } from "components";

import "./App.css";

import { AxiosWatcher } from "watchers/AxiosWatcher";

function App() {
  const { isLoggedIn } = useAuth();

  const adminRedirectPath = isLoggedIn ? "/admin/logs" : LOGIN;
  return (
    <>
      <AxiosWatcher />

      <Routes>
        <Route path="*" element={<Navigate replace to={"/"} />} />
        <Route path="/" element={<Home />} />

        <Route
          path="/admin/*"
          element={<Navigate replace to={adminRedirectPath} />}
        />

        {!isLoggedIn && <Route path={LOGIN} element={<Login />} />}

        {Object.keys(ADMIN_ROUTES).map(key => {
          const { Component, path } = ADMIN_ROUTES[key];
          return (
            <Route
              key={key}
              path={path}
              element={
                <AuthRequired>
                  <AdminLayout>
                    <Component />
                  </AdminLayout>
                </AuthRequired>
              }
            />
          );
        })}
      </Routes>
    </>
  );
}

export default App;
