// Lib
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SubmitHandler, useForm, Controller } from "react-hook-form";
// Api
import { useLoginMutation } from "api/hooks/auth";
// Hooks
import { useAuth } from "hooks";
// Icons
import { EmailIcon, Logo, PasswordIcon } from "icons";
// Components
import { Checkbox, Input, Button } from "components/Form";

import styles from "./styles.module.scss";

import { resolver } from "./validation";

type LoginFormTypes = {
  email: string;
  password: string;
  rememberMe: boolean;
};

export const Login = () => {
  const navigate = useNavigate();
  const { isLoggedIn } = useAuth();

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/admin/logs");
    }
  }, [isLoggedIn]);

  const { mutate, isPending, error } = useLoginMutation();

  const { handleSubmit, control, watch } = useForm<LoginFormTypes>({
    defaultValues: {
      email: "",
      password: "",
      rememberMe: false,
    },
    resolver,
  });

  const { email, password } = watch();

  const onSubmit: SubmitHandler<LoginFormTypes> = async data => {
    mutate(data);
  };

  const isSubmitDisabled = !email || password?.length < 8;

  const isPasswordError =
    error?.response?.data?.statusCode === 404 ||
    error?.response?.data?.statusCode === 400;

  return (
    <div className={styles.root}>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <Logo className={styles.logo} />
        <div className={styles.inputWrapper}>
          <p className={styles.description}>Login to manage your account</p>

          <Controller
            name={"email"}
            control={control}
            render={({ field, fieldState }) => (
              <Input
                type="text"
                prefix={<EmailIcon />}
                placeholder="Email"
                isDisabled={isPending}
                {...field}
                fieldState={fieldState}
              />
            )}
          />

          <Controller
            name={"password"}
            control={control}
            render={({ field, fieldState }) => (
              <Input
                type="password"
                prefix={<PasswordIcon />}
                placeholder="Password"
                isDisabled={isPending}
                {...field}
                fieldState={fieldState}
              />
            )}
          />

          <Button
            text="Sign in"
            fullwidth
            size="56"
            htmlType="submit"
            disabled={isSubmitDisabled || isPending}
          />

          <Controller
            name={"rememberMe"}
            control={control}
            render={({ field }) => (
              <Checkbox
                label="Remember Me"
                {...field}
                checked={field.value}
                onChange={e => field.onChange(e.target.checked)}
              />
            )}
          />
        </div>

        {!!isPasswordError && (
          <div className={styles.errorContainer}>
            Invalid email or password. Please check and try again
          </div>
        )}
      </form>
    </div>
  );
};
