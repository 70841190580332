import React, { SVGProps } from "react";

export const MousePointer = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8243 11.7564C13.8243 11.7564 13.8255 11.7564 13.826 11.7569L28.2936 16.7646H28.2944C29.5579 17.1985 29.4985 18.9753 28.2491 19.3638H28.2479L21.7082 21.3817C21.7069 21.3817 21.7056 21.3826 21.7043 21.383L19.6894 27.9227V27.9244C19.3009 29.1737 17.5237 29.2327 17.0898 27.9697V27.9688L12.0821 13.5008C12.0821 13.5008 12.0821 13.4995 12.0816 13.4991C11.9966 13.2563 11.9816 12.9947 12.0389 12.7438C12.0962 12.4925 12.2231 12.2625 12.4056 12.08C12.5877 11.8979 12.8177 11.771 13.069 11.7137C13.3199 11.6568 13.5815 11.6714 13.8243 11.7564ZM12.7275 13.2742L13.3738 13.0507L18.3824 27.5213C18.3824 27.5213 18.3828 27.52 18.3832 27.5192L20.3985 20.9778C20.4622 20.7598 20.5806 20.5619 20.7426 20.4029C20.9029 20.2456 21.1004 20.1319 21.3162 20.0721L27.8435 18.0585C27.8444 18.0585 27.8452 18.058 27.8461 18.0576L13.3725 13.0473L12.7275 13.2738V13.2742Z"
        fill="#BABABA"
      />
    </svg>
  );
};
