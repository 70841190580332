import { Logo } from "../../icons";
import style from "./style.module.scss";
import { Button } from "components/Form/Button";
import { FC } from "react";

interface HeaderProps {
  handleSubmitTask: () => Promise<void>;
  isActive: number | null;
}
export const Header: FC<HeaderProps> = ({ handleSubmitTask, isActive }) => {
  return (
    <nav className={style.nav}>
      <div className={"nav-logo"}>
        <Logo />
      </div>
      <div className={"nav-button-box"}>
        <Button
          textClassName={style.submitButton}
          onClick={handleSubmitTask}
          text={"Submit"}
          disabled={!isActive}
        />
      </div>
    </nav>
  );
};
