import { FC, Fragment, useMemo } from "react";
import { Circle, Rect } from "react-konva";
import Points from "./Points";
import { BoundingBoxProps } from "./typed";
import { ToolType } from "../../typed";

export const BoundingBoxTool: FC<BoundingBoxProps> = ({
  boxes,
  selected,
  edit,
  tempBoundingBox,
  handleCornerMouseDown,
  handleCornerDragMove,
  setDraggingCorner,
  setSelectedBoxIndex,
  setSelectedType,
  scale,
  mousePosition,
  admin,
}) => {
  const handleDeleteBox = (index: number, type: string) => {
    if (edit) {
      setSelectedType(type);
      setSelectedBoxIndex(index);
    }
  };
  const pointsList = useMemo(() => {
    return boxes?.map((box, boxIndex) => (
      <Fragment key={boxIndex}>
        {box.shape === ToolType.BoundingBox && (
          <>
            <Rect
              x={box.left}
              y={box.top}
              width={box.width}
              height={box.height}
              fill="rgba(220, 26, 125, 0.2)"
              stroke="#FF0083"
              strokeWidth={edit && selected === boxIndex ? 2 : 1}
              lineJoin="round"
              dash={edit && selected === boxIndex ? [4, 4] : []}
              onClick={() => handleDeleteBox(boxIndex, box.shape)}
            />
            <Points
              box={box}
              handleCornerMouseDown={handleCornerMouseDown}
              handleCornerDragMove={handleCornerDragMove}
              setDraggingCorner={setDraggingCorner}
              setSelectedBoxIndex={setSelectedBoxIndex}
              index={boxIndex}
              admin={admin}
            />
          </>
        )}
      </Fragment>
    ));
  }, [
    boxes,
    selected,
    handleCornerMouseDown,
    handleCornerDragMove,
    setDraggingCorner,
    setSelectedBoxIndex,
  ]);
  const tempBoundingBoxPoints = useMemo(() => {
    if (
      tempBoundingBox &&
      tempBoundingBox.left &&
      tempBoundingBox.top &&
      tempBoundingBox.width &&
      tempBoundingBox.height
    ) {
      const x1 = tempBoundingBox.left;
      const y1 = tempBoundingBox.top;
      const x2 = tempBoundingBox.left + tempBoundingBox.width;
      const y2 = tempBoundingBox.top;
      const x3 = tempBoundingBox.left + tempBoundingBox.width;
      const y3 = tempBoundingBox.top + tempBoundingBox.height;
      const x4 = tempBoundingBox.left;
      const y4 = tempBoundingBox.top + tempBoundingBox.height;
      return [
        { x: x1, y: y1 },
        { x: x2, y: y2 },
        { x: x3, y: y3 },
        { x: x4, y: y4 },
      ];
    }
    return [];
  }, [tempBoundingBox, scale]);
  return (
    <>
      {tempBoundingBox && (
        <>
          <Rect
            x={tempBoundingBox.left}
            y={tempBoundingBox.top}
            width={tempBoundingBox.width}
            height={tempBoundingBox.height}
            fill="rgba(220, 26, 125, 0.2)"
            stroke="#FF0083"
            strokeWidth={2}
            dash={[4, 4]}
            shadowColor={"rgba(0, 0, 0, 1)"}
            visible={!edit}
          />
          {tempBoundingBoxPoints.map((point, index) => (
            <Circle
              width={12}
              key={index}
              x={point.x}
              y={point.y}
              fill={"rgba(255, 255, 255, 1)"}
              stroke={"rgba(255, 0, 131, 1)"}
              strokeWidth={2}
            />
          ))}
        </>
      )}
      {pointsList}
    </>
  );
};
