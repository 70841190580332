import { SVGProps } from "react";

export const LogoutIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.02045 19.0762C2.11224 19.0762 1.42963 18.8477 0.972595 18.3906C0.521423 17.9336 0.295837 17.2451 0.295837 16.3252V2.99219C0.295837 2.07812 0.521423 1.38965 0.972595 0.926758C1.42963 0.463867 2.11224 0.232422 3.02045 0.232422H12.3544C13.2626 0.232422 13.9423 0.463867 14.3935 0.926758C14.8505 1.38965 15.079 2.07812 15.079 2.99219V7.06152H13.664V3.01855C13.664 2.5791 13.5468 2.24219 13.3124 2.00781C13.0839 1.76758 12.7411 1.64746 12.2841 1.64746H3.09076C2.63373 1.64746 2.28802 1.76758 2.05365 2.00781C1.82513 2.24219 1.71088 2.5791 1.71088 3.01855V16.2988C1.71088 16.7383 1.82513 17.0752 2.05365 17.3096C2.28802 17.5439 2.63373 17.6611 3.09076 17.6611H12.2841C12.7411 17.6611 13.0839 17.5439 13.3124 17.3096C13.5468 17.0752 13.664 16.7383 13.664 16.2988V12.2471H15.079V16.3252C15.079 17.2451 14.8505 17.9336 14.3935 18.3906C13.9423 18.8477 13.2626 19.0762 12.3544 19.0762H3.02045ZM8.42572 10.3486C8.24408 10.3486 8.08588 10.2812 7.95111 10.1465C7.81635 10.0059 7.74896 9.8418 7.74896 9.6543C7.74896 9.4668 7.81635 9.30273 7.95111 9.16211C8.08588 9.02148 8.24408 8.95117 8.42572 8.95117H17.4521L18.7704 9.0127L18.1464 8.41504L16.7314 7.0791C16.5908 6.95605 16.5204 6.79492 16.5204 6.5957C16.5204 6.41406 16.5761 6.26465 16.6874 6.14746C16.8046 6.02441 16.954 5.96289 17.1357 5.96289C17.3056 5.96289 17.4609 6.0332 17.6015 6.17383L20.4667 9.14453C20.5546 9.23242 20.6132 9.31738 20.6425 9.39941C20.6777 9.48145 20.6953 9.56641 20.6953 9.6543C20.6953 9.74219 20.6777 9.82715 20.6425 9.90918C20.6132 9.99121 20.5546 10.0762 20.4667 10.1641L17.6015 13.1348C17.4609 13.2695 17.3056 13.3369 17.1357 13.3369C16.954 13.3369 16.8046 13.2754 16.6874 13.1523C16.5761 13.0293 16.5204 12.877 16.5204 12.6953C16.5204 12.502 16.5908 12.3438 16.7314 12.2207L18.1464 10.8936L18.7704 10.2959L17.4521 10.3486H8.42572Z"
        fill="white"
        fillOpacity="0.64"
      />
    </svg>
  );
};
