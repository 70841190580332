import { Dispatch, SetStateAction } from "react";
import Konva from "konva";
import KonvaEventObject = Konva.KonvaEventObject;
import { Shape } from "components/AnnotateTool/typed";

export const handleDragPoint = (
  e: KonvaEventObject<DragEvent>,
  polygonIndex: number,
  pointIndex: number,
  admin: boolean,
  setShapes: Dispatch<SetStateAction<Shape[]>>,
) => {
  if (admin) return;
  const vertexRadius = 4;
  const newX = e.target.x() + vertexRadius / 2;
  const newY = e.target.y() + vertexRadius / 2;
  setShapes(prevShapes => {
    const updatedShapes = [...prevShapes];
    if (updatedShapes[polygonIndex] && updatedShapes[polygonIndex].points) {
      const updatedPoints = updatedShapes[polygonIndex].points;
      if (updatedPoints) {
        updatedPoints[pointIndex] = { left: newX, top: newY };
        updatedShapes[polygonIndex].points = updatedPoints;
      }
    }
    return updatedShapes;
  });
};
