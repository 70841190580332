import { FC, Fragment, useState } from "react";
import { Circle, Group, Line } from "react-konva";
import { Shape, ToolType } from "../../typed";
import { PolygonToolProps } from "./typed";

export const PolygonTool: FC<PolygonToolProps> = ({
  polygons,
  handleDragPoint,
  selectedBoxIndex,
  setSelectedType,
  edit,
  setSelectedBoxIndex,
  setIsDraggingPolygonPoint,
  admin,
}) => {
  const vertexRadius = 4;
  const [highlightedPointIndices, setHighlightedPointIndices] = useState<
    number[]
  >([]);

  const handlePointMouseEnter = (shapeIndex: number, pointIndex: number) => {
    setHighlightedPointIndices(prevIndices => [
      ...prevIndices,
      shapeIndex * 1000 + pointIndex,
    ]);
  };

  const handlePointMouseLeave = () => {
    setHighlightedPointIndices([]);
  };
  const handleDeleteBox = (index: number, type: string) => {
    if (edit) {
      setSelectedType(type);
      setSelectedBoxIndex(index);
    }
  };

  return (
    <Group name="polygon">
      {polygons?.map((polygon: Shape, shapeIndex: number) => {
        const convertedPoints = polygon?.points
          ?.map(point => [point.left, point.top])
          .flat();
        return (
          <Fragment key={shapeIndex}>
            {polygon.shape === ToolType.Polygon && (
              <Fragment>
                <Line
                  points={convertedPoints}
                  stroke={"rgba(17, 109, 255, 1)"}
                  dash={
                    (edit && selectedBoxIndex === shapeIndex) ||
                    (!edit && !polygon.isFinished)
                      ? [4, 4]
                      : []
                  }
                  strokeWidth={2}
                  lineCap="round"
                  lineJoin="round"
                  closed={polygon.isFinished}
                  onClick={() => handleDeleteBox(shapeIndex, polygon.shape)}
                  fill="rgba(31, 70, 130, 0.5)"
                />
                {polygon?.points &&
                  polygon?.points.map((point, pointIndex: number) => {
                    const x = point && point.left - vertexRadius / 2;
                    const y = point && point.top - vertexRadius / 2;
                    const isFirstPoint = pointIndex === 0;
                    const isFinished = polygon.isFinished;
                    const isHighlighted = isFinished
                      ? highlightedPointIndices.includes(
                          shapeIndex * 1000 + pointIndex,
                        )
                      : isFirstPoint &&
                        highlightedPointIndices.includes(
                          shapeIndex * 1000 + pointIndex,
                        );
                    return (
                      <Group
                        key={pointIndex}
                        onDragEnd={() => {
                          setSelectedBoxIndex(null);
                          setIsDraggingPolygonPoint(null);
                        }}
                        onDragMove={e => {
                          handleDragPoint(e, shapeIndex, pointIndex);
                          handleDeleteBox(shapeIndex, polygon.shape);
                          setIsDraggingPolygonPoint(pointIndex);
                        }}
                        onMouseEnter={() =>
                          handlePointMouseEnter(shapeIndex, pointIndex)
                        }
                        onMouseLeave={handlePointMouseLeave}
                        onClick={() =>
                          handleDeleteBox(shapeIndex, polygon.shape)
                        }
                        draggable={!admin}
                        x={x}
                        y={y}
                      >
                        <Circle
                          width={isHighlighted ? 32 : 12}
                          fill={
                            isHighlighted
                              ? "rgba(31, 70, 130, 0.5)"
                              : "rgba(250, 250, 250, 1)"
                          }
                          stroke={
                            isHighlighted
                              ? "rgba(250, 250, 250, 1)"
                              : "rgba(17, 109, 255, 1)"
                          }
                          strokeWidth={isHighlighted ? 1 : 2}
                        />
                        <Circle
                          width={12}
                          fill={"rgba(250, 250, 250, 1)"}
                          stroke={"rgba(17, 109, 255, 1)"}
                          strokeWidth={1}
                          visible={isHighlighted}
                        />
                      </Group>
                    );
                  })}
              </Fragment>
            )}
          </Fragment>
        );
      })}
    </Group>
  );
};
