import { yupResolver } from "@hookform/resolvers/yup";
import { yupPasswordRequiredValidation } from "ulits/heplers";
import { boolean, object, string } from "yup";

const schema = object({
  email: string().email("Email not valid").required("Email is required"),
  password: yupPasswordRequiredValidation(),
  rememberMe: boolean().required(),
}).required();

export const resolver = yupResolver(schema);
