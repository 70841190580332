import { SVGProps } from "react";

export const EyeIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="15"
      viewBox="0 0 22 15"
      {...props}
      fill="none"
    >
      <g clipPath="url(#clip0_2439_1438)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.4853 7.79925C18.5685 11.241 14.9337 13.3792 11.0012 13.3792C7.06861 13.3792 3.43109 11.241 1.51468 7.79925C1.36715 7.53204 1.36715 7.24952 1.51468 6.98231C3.43109 3.54056 7.06582 1.4024 11.0012 1.4024C14.9365 1.4024 18.5685 3.5401 20.4853 6.98231C20.6357 7.24952 20.6357 7.53204 20.4853 7.79925ZM21.7096 6.2999C19.5459 2.41372 15.4426 0 11.0012 0C6.5597 0 2.45363 2.41372 0.29042 6.2999C-0.0964811 6.99344 -0.0964811 7.78812 0.29042 8.4812C2.4541 12.3674 6.55738 14.7816 11.0012 14.7816C15.445 14.7816 19.5459 12.3674 21.7096 8.4812C22.0965 7.78858 22.0965 6.99344 21.7096 6.2999ZM11.0012 10.2677C12.5868 10.2677 13.8774 8.97712 13.8774 7.39055C13.8774 5.80398 12.5868 4.51338 11.0012 4.51338C9.41553 4.51338 8.12215 5.80398 8.12215 7.39055C8.12215 8.97712 9.41274 10.2677 11.0012 10.2677ZM11.0012 3.11098C8.63894 3.11098 6.71975 5.0311 6.71975 7.39055C6.71975 9.74999 8.63894 11.6697 11.0012 11.6697C13.3634 11.6697 15.2798 9.75046 15.2798 7.39055C15.2798 5.03064 13.3606 3.11098 11.0012 3.11098Z"
          fill="#FAFAFA"
        />
      </g>
      <defs>
        <clipPath id="clip0_2439_1438">
          <rect width="22" height="14.7811" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
