import { FC, useState } from "react";
import { Circle, Group } from "react-konva";
import Konva from "konva";
import KonvaEventObject = Konva.KonvaEventObject;
import { ToolType } from "../../typed";
import { PointMarkerProps } from "./typed";

export const PointTool: FC<PointMarkerProps> = ({
  points,
  setPoints,
  setSelectedType,
  edit,
  selected,
  setSelectedBoxIndex,
  admin,
}) => {
  const [selectedStyled, setSelectedStyled] = useState<number | null>(null);
  const handlePointDragMove = (
    index: number,
    e: KonvaEventObject<DragEvent>,
  ) => {
    const stage = e.target.getStage();
    const position = stage && stage.getPointerPosition();
    if (position) {
      const newPoints = [...points];
      newPoints[index] = {
        shape: ToolType.Point,
        left: position.x,
        top: position.y,
      };
      setPoints(newPoints);
    }
  };
  const handlePointDelete = (index: number, type: string) => {
    if (edit && type) {
      setSelectedType(type);
      setSelectedBoxIndex(index);
    }
  };

  return (
    <>
      {points?.map((point, index) => {
        if (point.shape === ToolType.Point) {
          return (
            <Group
              key={index}
              x={point.left}
              y={point.top}
              draggable={!admin}
              onClick={e => {
                handlePointDelete(index, point.shape);
                console.log(e);
              }}
              onDragStart={e => handlePointDragMove(index, e)}
              onMouseEnter={() => setSelectedStyled(index)}
              onMouseLeave={() => setSelectedStyled(null)}
            >
              <Circle
                width={32}
                height={32}
                fill={
                  selected === index || selectedStyled === index
                    ? "rgba(234, 150, 25, 1)"
                    : "rgba(234, 150, 25, 0.2)"
                }
                stroke="rgba(250, 250, 250, 1)"
                strokeWidth={1}
              />
              <Circle
                width={12}
                fill={"rgba(250, 250, 250, 1)"}
                stroke={"rgba(234, 150, 25, 1)"}
                strokeWidth={1}
              />
            </Group>
          );
        }
        return null;
      })}
    </>
  );
};
