import { useContext } from "react";
// Context
import { AuthContext } from "context/AuthContext";

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error("AuthContext must be within AuthProvider");
  }

  return context;
};
