import React, { SVGProps } from "react";

export const Logo = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="78"
      height="24"
      viewBox="0 0 78 24"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.0927734 12.0674H11.994V23.9686H6.09278C2.77906 23.9686 0.0927734 21.2823 0.0927734 17.9686V12.0674Z"
        fill="#FAFAFA"
      />
      <path
        d="M0.0927734 6.0332C0.0927734 2.71949 2.77906 0.0332031 6.09278 0.0332031H11.994C11.994 6.60605 6.66562 11.9344 0.0927734 11.9344V6.0332Z"
        fill="#FAFAFA"
      />
      <path
        d="M12.1262 0.0332031H18.0275C21.3411 0.0332031 24.0275 2.71949 24.0275 6.0332V11.9344H12.1262V0.0332031Z"
        fill="#FAFAFA"
      />
      <path
        d="M12.1262 23.9686C12.1262 17.3957 17.4546 12.0674 24.0275 12.0674V17.9686C24.0275 21.2823 21.3411 23.9686 18.0275 23.9686H12.1262Z"
        fill="#FAFAFA"
      />
      <path
        d="M33.0471 6.6711H28.8274V5.09766H39.0905V6.6711H34.8708V18.0606H33.0471V6.6711ZM43.7417 10.1398C43.2888 10.0921 42.9849 10.0683 42.8298 10.0683C41.3279 10.0683 40.577 11.0517 40.577 13.0185V18.0606H38.9499V8.44122H40.5412V10.1219C41.0418 9.00145 41.9478 8.42334 43.259 8.38758C43.4616 8.38758 43.6226 8.39353 43.7417 8.40546V10.1398ZM46.9328 8.44122V18.0606H45.3057V8.44122H46.9328ZM46.9328 5.09766V7.06446H45.3057V5.09766H46.9328ZM51.1409 9.8895C51.7965 8.8167 52.8335 8.2803 54.252 8.2803C56.3619 8.2803 57.4168 9.51402 57.4168 11.9815V18.0606H55.7897V12.4642C55.7897 11.5821 55.6704 10.9325 55.4321 10.5153C55.1103 9.96697 54.5261 9.69282 53.6799 9.69282C52.8812 9.69282 52.2614 9.96102 51.8204 10.4974C51.3674 11.0457 51.1409 11.8265 51.1409 12.8397V18.0606H49.5138V8.44122H51.1409V9.8895ZM61.4777 8.44122V18.0606H59.8506V8.44122H61.4777ZM61.4777 5.09766V7.06446H59.8506V5.09766H61.4777ZM64.5237 6.02742H66.1508V8.44122H67.9745V9.74646H66.1508V15.4501C66.1508 15.9747 66.2044 16.3084 66.3117 16.4514C66.4308 16.6183 66.7109 16.7017 67.152 16.7017H67.9745V18.0606H66.7944C65.8647 18.0606 65.2568 17.9057 64.9707 17.5957C64.6726 17.2859 64.5237 16.6362 64.5237 15.6468V9.74646H63.0039V8.44122H64.5237V6.02742ZM76.918 8.44122L73.485 18.4719C73.1274 19.5567 72.752 20.2719 72.3586 20.6175C71.9175 20.9989 71.1785 21.1896 70.1415 21.1896H69.3011V19.8307H69.9627C70.5706 19.8307 70.9877 19.7533 71.2143 19.5983C71.5241 19.3957 71.7804 18.9487 71.9831 18.2573L72.0546 18.0606L68.5859 8.44122H70.3918L72.8414 15.8614L75.2194 8.44122H76.918Z"
        fill="#FAFAFA"
      />
    </svg>
  );
};
