import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { Layer, Stage } from "react-konva";
import Konva from "konva";
import KonvaEventObject = Konva.KonvaEventObject;

import { ConfirmDialog } from "components/Modals";
import {
  useGetMousePos,
  handleCornerDragMove,
  handleCornerMouseDown,
  handleStageMouseMove,
  useKeyPressEffect,
  useAutoFillPolygon,
  useDeleteUnfinishedPolygon,
  useDeleteVertex,
  handleDragPoint,
  handleStageClick,
} from "ulits/heplers";

import {
  BoundingBoxTool,
  HandlersBottomButtons,
  HeaderBox,
  ImageView,
  PointTool,
  PolygonTool,
} from "./components";

import { AnnotateToolProps, Shape, ToolType } from "./typed";
import styles from "./style.module.scss";

export const AnnotateTool: FC<AnnotateToolProps> = ({
  imageUrl,
  setActiveTool,
  activeTool,
  data,
  shapes,
  setShapes,
  admin,
}) => {
  const edit = activeTool === ToolType.Edit;

  const [image, setImage] = useState<HTMLImageElement | null>(null);
  const [selectedBoxIndex, setSelectedBoxIndex] = useState<number | null>(null);
  const [selectedType, setSelectedType] = useState<string>("");
  const [draggingCorner, setDraggingCorner] = useState<string | null>(null);
  const [startPoint, setStartPoint] = useState<{
    x: number | undefined;
    y: number | undefined;
  } | null>(null);
  const [startSize, setStartSize] = useState<{
    width: number;
    height: number;
  } | null>(null);
  const [tempBoundingBox, setTempBoundingBox] = useState<Shape | null>(null);
  const [creatingBoundingBox, setCreatingBoundingBox] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [scale, setScale] = useState(100);
  const [_, setIsDraggingPolygonPoint] = useState<number | null>(null);
  const [creatingPolygon, setCreatingPolygon] = useState<boolean>(false);
  const [polygonLength, setPolygonLength] = useState<number | null>(null);
  const [isResetModalOpen, setIsResetModalOpen] = useState<boolean>(false);
  const [isDraggingImage, setIsDraggingImage] = useState<boolean>(false);
  useEffect(() => {
    const img = new window.Image();
    img.src = imageUrl;
    img.onload = () => setImage(img);
  }, [imageUrl]);

  const helperKeyPressFunc = {
    setActiveTool,
    setSelectedBoxIndex,
    admin,
  };
  useKeyPressEffect(helperKeyPressFunc);

  useEffect(() => {
    if (activeTool !== ToolType.BoundingBox) setTempBoundingBox(null);
  }, [activeTool]);
  const handleAutoFillPolygon = (event?: KeyboardEvent) => {
    useAutoFillPolygon(activeTool, setShapes);
  };

  useEffect(() => {
    const handleAutoFill = (event: KeyboardEvent) => {
      if (event.code === "KeyA") {
        if (activeTool === ToolType.Polygon) {
          handleAutoFillPolygon(event);
          setCreatingPolygon(false);
        } else if (activeTool === ToolType.BoundingBox && tempBoundingBox) {
          handleStageOnClick();
        }
      }
    };

    window.addEventListener("keydown", handleAutoFill);

    return () => {
      window.removeEventListener("keydown", handleAutoFill);
    };
  }, [activeTool, tempBoundingBox]);

  const handleStageOnClick = (e?: KonvaEventObject<MouseEvent>) => {
    handleStageClick(
      e,
      admin,
      activeTool,
      shapes,
      setShapes,
      tempBoundingBox,
      setTempBoundingBox,
      setCreatingBoundingBox,
      setCreatingPolygon,
      setPolygonLength,
      edit,
    );
  };

  const deleteUnfinishedPolygon = (
    setShapes: Dispatch<SetStateAction<Shape[]>>,
    setCreatingPolygon: Dispatch<SetStateAction<boolean>>,
  ) => {
    useDeleteUnfinishedPolygon(setShapes, setCreatingPolygon);
  };
  const handleDeleteCurrentPolygon = (event: KeyboardEvent) => {
    if (admin) return;
    if (event.code === "KeyS")
      if (activeTool === ToolType.Polygon) {
        deleteUnfinishedPolygon(setShapes, setCreatingPolygon);
      } else if (activeTool === ToolType.BoundingBox) {
        setTempBoundingBox(null);
      }
  };

  useEffect(() => {
    const handleAutoDelete = (event: KeyboardEvent) => {
      handleDeleteCurrentPolygon(event);
    };

    window.addEventListener("keydown", handleAutoDelete);

    return () => {
      window.removeEventListener("keydown", handleAutoDelete);
    };
  }, [activeTool]);
  useEffect(() => {
    const handleAutoDelete = (event: KeyboardEvent) => {
      handleDeleteSelectedBox(event);
    };

    window.addEventListener("keydown", handleAutoDelete);

    return () => {
      window.removeEventListener("keydown", handleAutoDelete);
    };
  }, [edit]);

  const onMouseMove = (e: KonvaEventObject<MouseEvent>) => {
    if (admin) return;
    handleStageMouseMove(
      e,
      tempBoundingBox,
      setTempBoundingBox,
      setMousePosition,
      scale,
    );
  };

  const onMouseDown = (boxIndex: number, corner: string) => {
    if (admin) return;
    handleCornerMouseDown(
      boxIndex,
      corner,
      shapes,
      setSelectedBoxIndex,
      setDraggingCorner,
      setStartPoint,
      setStartSize,
    );
  };

  const handleCornerDragMoves = (
    e: KonvaEventObject<DragEvent>,
    boxIndex: number,
    corner?: string | null | undefined,
  ) => {
    if (admin) return;
    handleCornerDragMove(
      e,
      boxIndex,
      corner,
      shapes,
      draggingCorner,
      startPoint,
      startSize,
      setShapes,
      setMousePosition,
    );
  };
  const handleDragPolygonPoint = (
    e: KonvaEventObject<DragEvent>,
    polygonIndex: number,
    pointIndex: number,
  ) => {
    handleDragPoint(e, polygonIndex, pointIndex, admin, setShapes);
  };

  const handleDeleteSelectedBox = (event?: KeyboardEvent) => {
    if (selectedBoxIndex !== null || event?.code === "KeyX") {
      const newBoxes = [...shapes];
      newBoxes.splice(selectedBoxIndex, 1);
      setShapes(newBoxes);
      setSelectedBoxIndex(null);
    }
  };

  const handleDeselectBox = () => {
    setSelectedBoxIndex(null);
  };
  const handlePopup = () => {
    setIsResetModalOpen(true);
  };
  const handleResetBoxes = () => {
    setShapes([]);
    setIsResetModalOpen(false);
  };

  const handleDeletePolygonVertex = (index: number) => {
    useDeleteVertex(index, shapes, setShapes, setSelectedBoxIndex);
  };
  const handleCreateShape = () => {
    if (activeTool === ToolType.Polygon) {
      handleAutoFillPolygon();
      setCreatingPolygon(false);
    } else if (activeTool === ToolType.BoundingBox && tempBoundingBox) {
      handleStageOnClick();
    }
  };
  const handleCancelCreation = () => {
    if (activeTool === ToolType.Polygon) {
      useDeleteUnfinishedPolygon(setShapes, setCreatingPolygon);
    } else if (activeTool === ToolType.BoundingBox) {
      setTempBoundingBox(null);
    }
  };

  const scaled = scale / 100;
  const stagePadding = 48;
  const stageWidth = window.innerWidth - stagePadding - 77;
  const stageHeight = 790;
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <HeaderBox
          items={shapes.length}
          edit={edit}
          handlePopup={handlePopup}
          setScale={setScale}
          // @ts-ignore
          data={data?.task ? data.task : data?.template}
          scale={scale}
          // @ts-ignore
          task={(data && data?.task?.template?.data) || data?.template?.data}
          admin={admin}
          setIsDraggingImage={setIsDraggingImage}
          isDraggingImage={isDraggingImage}
        />
        <Stage
          width={stageWidth}
          height={stageHeight}
          scaleX={scaled}
          scaleY={scaled}
          className={styles.stage}
        >
          <Layer
            onClick={handleStageOnClick}
            onMouseMove={onMouseMove}
            width={stageWidth}
            height={stageHeight}
            draggable={isDraggingImage}
          >
            {image && (
              <ImageView
                stageWidth={stageWidth}
                stageHeight={stageHeight}
                scale={scaled}
                image={image}
                admin={admin}
                edit={activeTool === ToolType.BoundingBox}
                mousePosition={mousePosition}
                isDraggingImage={isDraggingImage}
              />
            )}
            <PointTool
              points={shapes}
              edit={edit}
              setPoints={setShapes}
              setSelectedType={setSelectedType}
              setSelectedBoxIndex={setSelectedBoxIndex}
              selected={selectedBoxIndex}
              admin={admin}
            />
            <BoundingBoxTool
              boxes={shapes}
              edit={edit}
              scale={scale}
              tempBoundingBox={tempBoundingBox}
              selected={selectedBoxIndex}
              setSelectedType={setSelectedType}
              handleCornerMouseDown={onMouseDown}
              handleCornerDragMove={handleCornerDragMoves}
              setDraggingCorner={setDraggingCorner}
              setSelectedBoxIndex={setSelectedBoxIndex}
              mousePosition={mousePosition}
              admin={admin}
            />
            <PolygonTool
              polygons={shapes}
              setSelectedBoxIndex={setSelectedBoxIndex}
              selectedBoxIndex={selectedBoxIndex}
              setSelectedType={setSelectedType}
              handleDragPoint={handleDragPolygonPoint}
              setIsDraggingPolygonPoint={setIsDraggingPolygonPoint}
              edit={edit}
              admin={admin}
            />
          </Layer>
        </Stage>
        {!admin && (
          <HandlersBottomButtons
            selectedType={selectedType}
            edit={edit}
            tempBoundingBox={tempBoundingBox}
            creatingBoundingBox={creatingBoundingBox}
            handleDeleteSelectedBox={handleDeleteSelectedBox}
            handleDeselectBox={handleDeselectBox}
            handleCreateShape={handleCreateShape}
            handleDeleteVertex={handleDeletePolygonVertex}
            selectedBoxIndex={selectedBoxIndex}
            creatingPolygon={creatingPolygon}
            activeTool={activeTool}
            polygonLength={polygonLength}
            handleCancelCreation={handleCancelCreation}
          />
        )}
        <ConfirmDialog
          open={isResetModalOpen}
          type="alert"
          message="Reset"
          description="Are you sure you want to reset all? This action cannot be undone"
          firstCTAButton={{
            fullwidth: true,
            type: "secondary",
            text: "Cancel",
            size: "56",
            onClick: () => setIsResetModalOpen(false),
          }}
          secondCTAButton={{
            fullwidth: true,
            text: "Reset",
            size: "56",
            onClick: handleResetBoxes,
          }}
        />
      </div>
    </div>
  );
};
