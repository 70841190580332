import { FC } from "react";
import cn from "classnames";

import { ButtonProps } from "types/common";

import style from "./style.module.scss";

export const Button: FC<ButtonProps> = ({
  buttonClassName,
  textClassName,
  Icon,
  type = "primary",
  size = "40",
  fullwidth,
  text,
  shortCode,
  disabled,
  isToolSelected,
  htmlType,
  onClick,
}) => {
  return (
    <button
      className={cn(style.btn, buttonClassName, style[`button-${type}`], {
        [style[`size-fullwidth`]]: fullwidth,
        [style[`selected-tool`]]: isToolSelected,
      })}
      type={htmlType}
      disabled={disabled}
      onClick={onClick}
    >
      <div
        className={cn(textClassName, style.text, style[`text-${type}`], {
          [style[`size-${size}`]]: size,
          [style[`size-fullwidth`]]: fullwidth,
        })}
      >
        {Icon && <Icon />}
        {text}{" "}
        {shortCode && <span className={style.shortCode}>{shortCode}</span>}
      </div>
    </button>
  );
};
