import { FC } from "react";
import { Line } from "react-konva";
import { CursorLinesProps, CustomLine } from "./typed";

export const CursorLines: FC<CursorLinesProps> = ({
  mousePosition,
  screenWidth,
  screenHeight,
}) => {
  if (!mousePosition) return null;

  const lines: CustomLine[] = [
    { points: [0, mousePosition.y, screenWidth, mousePosition.y] },
    { points: [mousePosition.x, 0, mousePosition.x, screenHeight] },
  ];

  return (
    <>
      {lines.map((line, index) => (
        <Line
          key={index}
          {...line}
          stroke="rgba(186, 186, 186, 1)"
          strokeWidth={1}
          dash={[5, 5]}
        />
      ))}
    </>
  );
};
