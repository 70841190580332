import { SVGProps } from "react";

export const PrevIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.789062 6.06445C0.789062 5.97786 0.805013 5.89811 0.836914 5.8252C0.868815 5.74772 0.916667 5.67708 0.980469 5.61328L6.40137 0.308594C6.52441 0.190104 6.67253 0.130859 6.8457 0.130859C6.96419 0.130859 7.06901 0.158203 7.16016 0.212891C7.25586 0.267578 7.33105 0.342773 7.38574 0.438477C7.44499 0.529622 7.47461 0.63444 7.47461 0.75293C7.47461 0.921549 7.41309 1.06966 7.29004 1.19727L2.31348 6.06445L7.29004 10.9316C7.41309 11.0592 7.47461 11.2074 7.47461 11.376C7.47461 11.4945 7.44499 11.5993 7.38574 11.6904C7.33105 11.7861 7.25586 11.8613 7.16016 11.916C7.06901 11.9707 6.96419 11.998 6.8457 11.998C6.67253 11.998 6.52441 11.9365 6.40137 11.8135L0.980469 6.51562C0.916667 6.45182 0.868815 6.38346 0.836914 6.31055C0.805013 6.23307 0.789062 6.15104 0.789062 6.06445Z"
        fill="url(#paint0_linear_58_6250)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_58_6250"
          x1="10.9125"
          y1="14.4792"
          x2="16.2491"
          y2="10.199"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9B1359" />
          <stop offset="0.461806" stopColor="#EA9619" />
          <stop offset="1" stopColor="#002CCA" />
        </linearGradient>
      </defs>
    </svg>
  );
};
