import { useMutation, useQuery } from "@tanstack/react-query";
import { useNotification } from "hooks";

import { BaseError } from "types/common";
import { TaskResponse } from "types/task";

import { endpoints } from "api/endpoints";

import { axiosInstance } from "ulits/axios";
import { errorHandler } from "ulits/errorHandler";
import { ShapesData, ShapesResponse } from "../../types/shapes";
import { generateQueryUrl } from "../../ulits/generateQueryUrl";
import { useState } from "react";
export const useTaskQuery = (
  uuid: string,
  type: string,
  location?: number | null,
  stop?: boolean,
) => {
  const [dataFetched, setDataFetched] = useState(false);
  const shouldFetch = !stop && !!location && !!uuid && !dataFetched;

  const data = useQuery<TaskResponse, BaseError>({
    queryKey: ["users/get-task", { uuid, type }],
    queryFn: async () => {
      try {
        if (!shouldFetch) return;
        const response = await axiosInstance.get(
          generateQueryUrl(endpoints.tasks.get, { uuid, type }),
        );
        setDataFetched(true);
        return response.data;
      } catch (error) {
        throw new Error("Failed to fetch task data");
      }
    },
    enabled: shouldFetch,
    staleTime: Infinity,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchIntervalInBackground: false,
  });
  return data;
};

export const useTaskMutation = () => {
  const { openNotification } = useNotification();
  const data = useMutation<ShapesResponse, BaseError, ShapesData>({
    mutationFn: data => axiosInstance.post(endpoints.tasks.post, { data }),
    onError: error =>
      openNotification({ message: errorHandler(error), type: "error" }),
  });

  return data;
};
