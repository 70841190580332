import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import { TABLE_DATE } from "consts";

dayjs.extend(utc);
dayjs.extend(timezone);

export const dateTransform = ({
  date,
  format = TABLE_DATE,
}: {
  date: string | Date | dayjs.Dayjs | null;
  format?: string;
}) => {
  if (!date) {
    return null;
  }
  return dayjs(date).format(format);
};

export const createDate = () => {
  return dayjs.utc().toISOString();
};

export const durationTransform = (data: number): string => {
  const totalSeconds = Math.floor(data / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(seconds).padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};
