import { BaseError } from "types/common";

export const errorHandler = (error: BaseError): string | null => {
  if (error?.response?.data?.statusCode === 401) {
    return null;
  }

  const message = error?.response?.data.message;
  if (!message) {
    return "Somethig went wrong";
  }
  if (typeof message === "string") {
    return message;
  }

  if (Array.isArray(message)) {
    const isNonStrings = message.find(el => typeof el !== "string");

    if (isNonStrings) {
      return "Somethig went wrong";
    }

    return message.join(". ");
  }

  return "Somethig went wrong";
};
