import { FC, Fragment, useEffect, useState } from "react";
import { Group, Image } from "react-konva";
import { CursorLines } from "../index";
import { ImageViewProps } from "./typed";

export const ImageView: FC<ImageViewProps> = ({
  image,
  stageWidth,
  stageHeight,
  edit,
  mousePosition,
  admin,
}) => {
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });

  const updateImageSize = () => {
    const { naturalWidth, naturalHeight } = image as HTMLImageElement;
    const aspectRatio = naturalWidth / naturalHeight;
    let newWidth = stageWidth;
    let newHeight = stageWidth / aspectRatio;
    if (newHeight > stageHeight) {
      newHeight = stageHeight;
      newWidth = stageHeight * aspectRatio;
    }
    setImageSize({ width: newWidth, height: newHeight });
  };
  useEffect(() => {
    updateImageSize();
  }, [image, stageWidth, stageHeight]);

  return (
    <Fragment>
      <Image
        image={image}
        width={imageSize.width}
        height={imageSize.height}
        cornerRadius={10}
      />
      {edit && mousePosition && !admin && (
        <CursorLines
          mousePosition={mousePosition}
          screenWidth={imageSize.width}
          screenHeight={imageSize.height}
        />
      )}
    </Fragment>
  );
};
