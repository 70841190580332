import { ListIcon, UserIcon, UsersIcon } from "icons";
import { SidebarMenuItemsType } from "types/common";

export const ADMIN_SIDEBAR_MENU_ITEMS: SidebarMenuItemsType = {
  admin: [
    {
      type: "base",
      Icon: UsersIcon,
      key: "sessions",
      label: "Users’ sessions",
      path: "/admin/sessions",
    },
    {
      type: "base",
      Icon: ListIcon,
      key: "logs",
      label: "Logs",
      path: "/admin/logs",
    },
    {
      type: "additional",
      Icon: UserIcon,
      key: "profile",
      label: "Profile",
      path: "/admin/profile",
    },
  ],
};
