import { useEffect, useRef, useState } from "react";

import { useTaskMutation, useTaskQuery } from "api/hooks/tasks";
import { HandlersBox, Layout, Header, AnnotateTool } from "components";
import { IconType } from "components/HandlersBox/typed";
import { useCreateSessionMutation } from "api/hooks/sessions";

import { generateUID, getActiveIconIndex } from "ulits/heplers";
import { createDate } from "ulits/dateTransform";
import { endpoints } from "api/endpoints";
import { ShapesData } from "types/shapes";
import { API_BASE_URL } from "ulits/config";
import { Shape } from "components/AnnotateTool/typed";
import { TaskResponse } from "types/task";
import { Spin } from "antd";

export const Home = () => {
  const [shapes, setShapes] = useState<Shape[]>([]);
  const [activeTool, setActiveTool] = useState<IconType["type"] | null>(null);
  const [image, setImage] = useState<string>("");
  const [taskData, setTaskData] = useState<TaskResponse | any>(null);
  const [location, setLocation] = useState<number | null>(null);
  const getTaskTime = useRef<string | null>(null);
  const [uuid, setUuid] = useState<string>(() => {
    const storedUuid = localStorage.getItem("uuid");
    return storedUuid || "";
  });
  const [sessionId, setSessionId] = useState<string>(() => {
    const storedUuid = localStorage.getItem("userUUID");
    return storedUuid || "";
  });
  const [type, setType] = useState<string>("license-plates");
  const { mutate: mutateSession, data: sessionResponse } =
    useCreateSessionMutation();
  const [stop, setStop] = useState(false);
  const { mutate, isSuccess, isPending, data: nextTData } = useTaskMutation();
  const { data, isError } = useTaskQuery(uuid, type, location, stop);
  useEffect(() => {
    if (data) {
      setTaskData(data);
      getTaskTime.current = createDate();
    }
  }, [data]);
  useEffect(() => {
    if (nextTData && isSuccess) {
      // @ts-ignore
      setTaskData(nextTData.data);
      getTaskTime.current = createDate();
    }
  }, [nextTData, isSuccess]);

  useEffect(() => {
    fetch("https://api64.ipify.org?format=json")
      .then(response => response.json())
      .then(data => {
        setLocation(data.ip);
        localStorage.setItem("location", data.ip);
      })
      .catch(error => console.error("Failed to fetch IP address:", error));
  }, []);

  useEffect(() => {
    if (sessionResponse?.data) {
      localStorage.setItem("userUUID", sessionResponse?.data?.id);
      localStorage.setItem("uuid", sessionResponse?.data?.client?.uuid);
      setUuid(sessionResponse?.data?.client?.uuid);
    }
  }, [sessionResponse]);

  useEffect(() => {
    if (isError) {
      setType("roblox");
    }
  }, [isError]);

  useEffect(() => {
    if (taskData) {
      setImage(taskData?.task?.imageUrl || taskData?.imageUrl);
    }
  }, [taskData, isSuccess]);

  useEffect(() => {
    if (isSuccess) {
      setShapes([]);
    }
  }, [isSuccess]);

  useEffect(() => {
    const sessionID = localStorage.getItem("userUUID");
    if (!sessionID && location) {
      const key = generateUID();
      const date = new Date();
      mutateSession({
        key: key,
        startTime: date,
        ip: location,
        uuid: uuid ? uuid : null,
      });
    }
  }, [location, sessionId]);

  useEffect(() => {
    const id = localStorage.getItem("uuid");
    const ip = localStorage.getItem("location");
    const handleVisibilityChange = () => {
      const key = generateUID();
      const date = new Date();
      setStop(true);
      if (document.visibilityState === "hidden") {
        endSession();
      } else {
        mutateSession({
          key: key,
          startTime: date,
          ip: ip,
          uuid: id ? id : null,
        });
      }
    };

    window.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      window.removeEventListener("visibilitychange", handleVisibilityChange);
      endSession();
    };
  }, [uuid]);

  const endSession = () => {
    const sessionID = localStorage.getItem("userUUID");
    if (sessionID) {
      const url = `${API_BASE_URL}${endpoints.session}/${sessionID}/end`;
      navigator.sendBeacon(url);
      localStorage.removeItem("userUUID");
    }
  };

  const handleSubmitTask = async () => {
    const startTime = getTaskTime.current;
    const endTime = createDate();
    const durationInMilliseconds =
      startTime && Date.parse(endTime) - Date.parse(startTime);
    const dataToSend: ShapesData = {
      type: type,
      uuid: taskData?.client?.uuid || uuid,
      taskId: taskData?.task?.id || taskData?.id,
      startTime: startTime,
      endTime: endTime,
      duration: durationInMilliseconds,
      result: shapes,
    };
    mutate(dataToSend);
    getTaskTime.current = null;
  };

  return (
    <Layout>
      <Header handleSubmitTask={handleSubmitTask} isActive={shapes?.length} />
      <div className={"flex-box"}>
        <HandlersBox
          setActiveTool={(tool: IconType["type"]) => setActiveTool(tool)}
          activeIconIndex={activeTool ? getActiveIconIndex(activeTool) : -1}
        />
        {!taskData || isPending ? (
          <Spin className={"spin"} size={"large"} />
        ) : (
          <AnnotateTool
            imageUrl={image ? image : ""}
            data={taskData ? taskData : null}
            setActiveTool={setActiveTool}
            activeTool={activeTool}
            shapes={shapes}
            setShapes={setShapes}
            admin={false}
          />
        )}
      </div>
    </Layout>
  );
};
