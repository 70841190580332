import { SVGProps } from "react";

export const SuccessIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.97168 15.415C5.62598 15.415 5.32129 15.2451 5.05762 14.9053L0.496094 9.0957C0.408203 8.99023 0.34375 8.88477 0.302734 8.7793C0.261719 8.66797 0.241211 8.55957 0.241211 8.4541C0.241211 8.21387 0.320312 8.01758 0.478516 7.86523C0.636719 7.70703 0.838867 7.62793 1.08496 7.62793C1.37793 7.62793 1.62988 7.77148 1.84082 8.05859L5.93652 13.4199L13.8291 0.842773C13.9346 0.678711 14.043 0.564453 14.1543 0.5C14.2656 0.429688 14.4092 0.394531 14.585 0.394531C14.8252 0.394531 15.0186 0.467773 15.165 0.614258C15.3115 0.760742 15.3848 0.954102 15.3848 1.19434C15.3848 1.2998 15.3672 1.40527 15.332 1.51074C15.2969 1.61035 15.2412 1.72168 15.165 1.84473L6.85938 14.9229C6.64258 15.251 6.34668 15.415 5.97168 15.415Z"
        fill="#1EC14C"
      />
    </svg>
  );
};
