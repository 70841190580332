import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { string } from "yup";
import { yupPasswordRequiredValidation } from "ulits/heplers";

const schema = yup.object().shape({
  password: yupPasswordRequiredValidation(),
  newPassword: string()
    .required("Repeat Password")
    .oneOf(
      [yup.ref("password")],
      "Passwords don’t match. Please check and try again",
    ),
});

const schema2 = yup.object().shape({
  checkPassword: yupPasswordRequiredValidation(),
});

export const resolver = yupResolver(schema);

export const checkResolver = yupResolver(schema2);
