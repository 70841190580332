import { Dispatch, SetStateAction } from "react";
import { Shape } from "../../components/AnnotateTool/typed";

export const handleCornerMouseDown = (
  boxIndex: number,
  corner: string,
  boxes: Shape[],
  setSelectedBoxIndex: Dispatch<SetStateAction<number | null>>,
  setDraggingCorner: Dispatch<SetStateAction<string | null>>,
  setStartPoint: Dispatch<
    SetStateAction<{ x: number | undefined; y: number | undefined } | null>
  >,
  setStartSize: Dispatch<
    SetStateAction<{ width: number; height: number } | null>
  >,
) => {
  setSelectedBoxIndex(boxIndex);
  setDraggingCorner(corner);
  const box = boxes[boxIndex];
  setStartPoint({ x: box.left, y: box.top });
  if (box.width && box.height) {
    setStartSize({ width: box.width, height: box.height });
  }
};
