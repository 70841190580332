import { FC } from "react";
import { Button } from "components/Form";

import { ToolType } from "../../typed";

import style from "./style.module.scss";
import { BottomButtonsProps } from "./typed";

export const HandlersBottomButtons: FC<BottomButtonsProps> = ({
  edit,
  selectedBoxIndex,
  handleDeleteSelectedBox,
  handleDeselectBox,
  handleDeleteVertex,
  tempBoundingBox,
  selectedType,
  creatingPolygon,
  activeTool,
  handleCancelCreation,
  handleCreateShape,
  polygonLength,
  creatingBoundingBox,
}) => {
  return (
    <div className={style.container}>
      {edit && selectedBoxIndex !== null && (
        <>
          <Button
            onClick={handleDeleteSelectedBox}
            type={"secondary"}
            text={`Delete ${selectedType}`}
            shortCode={"X"}
          />
          {selectedType === ToolType.Polygon && (
            <Button
              onClick={() => handleDeleteVertex(selectedBoxIndex)}
              type={"secondary"}
              text={`Delete vertex`}
            />
          )}
          <Button
            onClick={handleDeselectBox}
            type={"secondary"}
            text={"Deselect"}
          />
        </>
      )}
      {activeTool === ToolType.BoundingBox && tempBoundingBox && !edit && (
        <>
          <Button
            onClick={handleCreateShape}
            type={"secondary"}
            text={"Create a box"}
            shortCode={"A"}
            disabled={!tempBoundingBox}
          />
          <Button
            onClick={handleCancelCreation}
            type={"secondary"}
            text={"Cancel Creation"}
            shortCode={"S"}
          />
        </>
      )}
      {activeTool === ToolType.Polygon && !edit && creatingPolygon && (
        <>
          <Button
            onClick={handleCreateShape}
            type={"secondary"}
            text={"Create Polygon"}
            shortCode={"A"}
            disabled={!polygonLength}
          />
          <Button
            onClick={handleCancelCreation}
            type={"secondary"}
            text={"Cancel Creation"}
            shortCode={"S"}
          />
        </>
      )}
    </div>
  );
};
