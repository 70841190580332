import { Column } from "components/Table";

import { TableImage } from "components/Table/components/TableImage";
import { LogDataResponse } from "types/logs";

import { dateTransform, durationTransform } from "ulits/dateTransform";

export const columns: Column<LogDataResponse>[] = [
  {
    title: "Image",
    dataIndex: "task",
    key: "task",
    sorter: true,
    render: task => (
      <TableImage url={task?.imageUrl} name={task?.id.toString()} />
    ),
  },
  {
    title: "Date",
    dataIndex: "startTime",
    key: "createdAt",
    sorter: true,
    render: date => dateTransform({ date }),
  },
  {
    title: "Time of visit",
    dataIndex: "duration",
    key: "duration",
    sorter: true,
    render: (duration: number) => durationTransform(duration),
  },
  {
    title: "IP address",
    dataIndex: "client",
    key: "ip",
    sorter: true,
    render: client => client?.clientPublicIp,
  },
];
