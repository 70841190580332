import React, { SVGProps } from "react";

export const SeparatorIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M28.5 20L12.5 20" stroke="#373737" />
    </svg>
  );
};
