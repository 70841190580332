import { useNavigate, useSearchParams } from "react-router-dom";

import { DEFAULT_PAGE_SIZE } from "consts/config";
import { SortOrder } from "antd/es/table/interface";

type Order = "ASC" | "DESC" | "";

export const useTable = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const getParams = <T>(name: string, initial: T): T | string =>
    searchParams.get(name) || initial;

  const page = Number(searchParams.get("page") || 1);
  const limit = Number(searchParams.get("limit")) || DEFAULT_PAGE_SIZE;
  const sortBy = getParams("sortBy", "");
  const sort = getParams("sort", "") as Order;

  const handleSetParams = (
    params: Record<string, string | number | React.Key | readonly React.Key[]>,
  ) => {
    setSearchParams({
      ...(page && { page: page.toString() }),
      ...(limit && { limit: limit.toString() }),
      ...(sort && { sort }),
      ...(sortBy && { sortBy }),
      ...params,
    });
  };

  const setPage = (page: number) => {
    handleSetParams({ page });
  };

  const setLimit = (limit: number) => {
    handleSetParams({ page: 1, limit });
  };

  const handleSort = (
    field: React.Key | readonly React.Key[] | undefined,
    direction: SortOrder | undefined,
  ) => {
    if (!direction || !field) {
      handleSetParams({ sortBy: "", sortingOrder: "" });
      return;
    }

    const sortBy =
      direction === "ascend" ? "ASC" : direction === "descend" ? "DESC" : "";

    handleSetParams({ sortBy: field, sort: sortBy, page: 1 });
  };

  const handleReset = () => {
    setSearchParams({
      page: "1",
      limit: DEFAULT_PAGE_SIZE.toString(),
    });
  };

  const onTableRowClick = (path: string) => {
    const query = { page, limit, sortBy, sort };
    navigate(path, { state: { query } });
  };

  return {
    page,
    limit,
    sortBy,
    sort,
    setPage,
    setLimit,
    handleSort,
    handleReset,
    onTableRowClick,
  };
};
