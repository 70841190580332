import React, { SVGProps } from "react";

export const BoxToolIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.0697 24.5045V16.7845C26.7397 16.5345 27.2197 15.9045 27.2197 15.1445C27.2197 14.1745 26.4397 13.3945 25.4697 13.3945C24.7197 13.3945 24.0797 13.8745 23.8297 14.5445H16.1097C15.8597 13.8745 15.2297 13.3945 14.4697 13.3945C13.4997 13.3945 12.7197 14.1745 12.7197 15.1445C12.7197 15.8945 13.1997 16.5345 13.8697 16.7845V24.5045C13.1997 24.7545 12.7197 25.3845 12.7197 26.1445C12.7197 27.1145 13.4997 27.8945 14.4697 27.8945C15.2197 27.8945 15.8597 27.4145 16.1097 26.7445H23.8297C24.0797 27.4145 24.7097 27.8945 25.4697 27.8945C26.4397 27.8945 27.2197 27.1145 27.2197 26.1445C27.2197 25.3945 26.7397 24.7545 26.0697 24.5045ZM15.0697 24.5045V16.7845C15.5497 16.6045 15.9297 16.2245 16.1097 15.7445H23.8297C24.0097 16.2245 24.3897 16.6045 24.8697 16.7845V24.5045C24.3897 24.6845 24.0097 25.0645 23.8297 25.5445H16.1097C15.9297 25.0645 15.5497 24.6845 15.0697 24.5045Z"
        fill="#BABABA"
      />
    </svg>
  );
};
