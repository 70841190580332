import { SVGProps } from "react";

export const NextIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.21094 6.06445C7.21094 5.97786 7.19499 5.89811 7.16309 5.8252C7.13118 5.74772 7.08333 5.67708 7.01953 5.61328L1.59863 0.308594C1.47559 0.190104 1.32747 0.130859 1.1543 0.130859C1.03581 0.130859 0.93099 0.158203 0.839844 0.212891C0.744141 0.267578 0.668945 0.342773 0.614258 0.438477C0.555013 0.529622 0.525391 0.63444 0.525391 0.75293C0.525391 0.921549 0.586914 1.06966 0.709961 1.19727L5.68652 6.06445L0.709961 10.9316C0.586914 11.0592 0.525391 11.2074 0.525391 11.376C0.525391 11.4945 0.555013 11.5993 0.614258 11.6904C0.668945 11.7861 0.744141 11.8613 0.839844 11.916C0.93099 11.9707 1.03581 11.998 1.1543 11.998C1.32747 11.998 1.47559 11.9365 1.59863 11.8135L7.01953 6.51562C7.08333 6.45182 7.13118 6.38346 7.16309 6.31055C7.19499 6.23307 7.21094 6.15104 7.21094 6.06445Z"
        fill="url(#paint0_linear_58_6715)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_58_6715"
          x1="-2.9125"
          y1="14.4792"
          x2="-8.24909"
          y2="10.199"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9B1359" />
          <stop offset="0.461806" stopColor="#EA9619" />
          <stop offset="1" stopColor="#002CCA" />
        </linearGradient>
      </defs>
    </svg>
  );
};
