// Lib
import { Context, PropsWithChildren, createContext } from "react";
import { notification } from "antd";

interface NotificationButton {
  label: string;
  onClick: () => void;
}

export interface OpenNotificationType {
  duration?: number;
  message: string | null;
  description?: string;
  type?: "success" | "info" | "warning" | "error";
  notificationButton?: NotificationButton;
  onClose?: () => void;
}

export interface NotificationsContextType {
  openNotification: (args: OpenNotificationType) => void;
}

export const NotificationsContext = createContext<NotificationsContextType>(
  {} as NotificationsContextType,
);

export const NotificationProvider = ({ children }: PropsWithChildren) => {
  const [api, contextHolder] = notification.useNotification();

  const openNotification = ({
    duration = 5,
    message,
    description,
    type = "success",
    onClose,
  }: OpenNotificationType) => {
    if (!message) {
      return;
    }
    const key = type === "success" ? `open${Date.now()}` : "updatable";

    api[type]({
      placement: "bottomLeft",
      message,
      description,
      key,
      duration,
      onClose,
    });
  };

  return (
    <NotificationsContext.Provider value={{ openNotification }}>
      {children}
      {contextHolder}
    </NotificationsContext.Provider>
  );
};
