import { string } from "yup";

export const yupPasswordRequiredValidation = () => {
  return string()
    .trim()
    .required("Password is required")
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,}$/,
      "Password must have a minimum length of 8 characters, contain at least one symbol, one uppercase letter, one lowercase letter, and one number",
    );
};
