import axios, { AxiosInstance } from "axios";

import { API_BASE_URL } from "./config";
import { token } from "./handleToken";

export const CUSTOM_JSON_HEADERS = {
  "Content-Type": "application/json",
  accept: "application/json",
};

export const axiosInstanceRaw: AxiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: CUSTOM_JSON_HEADERS,
});

const axiosInstanceGenerator = (baseUrl?: string) => {
  const axiosInstance: AxiosInstance = axios.create({
    baseURL: baseUrl,
    headers: CUSTOM_JSON_HEADERS,
  });

  axiosInstance.interceptors.request.use(
    config => {
      const access = token.access.get();

      if (access?.token) {
        config.headers["Authorization"] = `Bearer ${access.token}`;
      }

      return config;
    },
    error => {
      return Promise.reject(error);
    },
  );

  return axiosInstance;
};

export const axiosInstance = axiosInstanceGenerator(API_BASE_URL);
