import { IconType } from "../../components/HandlersBox/typed";
import { ToolType } from "../../components/AnnotateTool/typed";

export const getActiveIconIndex = (activeTool: IconType["type"]): number => {
  switch (activeTool) {
    case ToolType.Edit:
      return 0;
    case ToolType.BoundingBox:
      return 3;
    case ToolType.Point:
      return 2;
    case ToolType.Polygon:
      return 4;
    default:
      return -1;
  }
};
