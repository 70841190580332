import { TaskBox } from "components";
import { FC, useRef, useState } from "react";

import { Button } from "components/Form";
import { ZoomControls } from "../ZoomControls";

import style from "./style.module.scss";
import { HeaderProps } from "./typed";
import { HandIcon } from "../../../../icons";

export const HeaderBox: FC<HeaderProps> = ({
  handlePopup,
  edit,
  items,
  scale,
  task,
  setScale,
  admin,
  setIsDraggingImage,
  isDraggingImage,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const handleZoomIn = () => {
    if (scale + 10 <= 200) {
      setScale(scale + 10);
    }
  };

  const handleZoomOut = () => {
    if (scale - 10 >= 10) {
      setScale(scale - 10);
    }
  };

  const handleResetZoom = () => {
    setScale(100);
  };
  const handleDragImage = () => {
    setIsDraggingImage(!isDraggingImage);
  };
  return (
    <div className={style.header} ref={ref}>
      <TaskBox task={task} maxWidth={ref.current?.clientWidth} />
      <div className={style.zoomBox}>
        {!admin ? (
          <Button
            onClick={handlePopup}
            text={"Reset"}
            disabled={!edit || !items}
            size={"40"}
            type={"secondary"}
            buttonClassName={"secondary"}
          />
        ) : (
          <Button
            isToolSelected={isDraggingImage}
            Icon={HandIcon}
            type={"tools"}
            onClick={() => handleDragImage()}
          />
        )}
        <ZoomControls
          scale={scale}
          onZoomIn={handleZoomIn}
          onZoomOut={handleZoomOut}
          onResetZoom={handleResetZoom}
        />
      </div>
    </div>
  );
};
