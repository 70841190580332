import Konva from "konva";
import KonvaEventObject = Konva.KonvaEventObject;
import { Shape, ToolType } from "../../components/AnnotateTool/typed";
import { Dispatch, SetStateAction } from "react";
import { useGetMousePos } from "./useGetMousePos";

export const handleStageClick = (
  e: KonvaEventObject<MouseEvent> | undefined,
  admin: boolean,
  activeTool: ToolType | null | undefined,
  shapes: Shape[],
  setShapes: Dispatch<SetStateAction<Shape[]>>,
  tempBoundingBox: Shape | null,
  setTempBoundingBox: Dispatch<SetStateAction<Shape | null>>,
  setCreatingBoundingBox: Dispatch<SetStateAction<boolean>>,
  setCreatingPolygon: Dispatch<SetStateAction<boolean>>,
  setPolygonLength: Dispatch<SetStateAction<number | null>>,
  edit: boolean,
) => {
  if (admin) return;
  const distanceThreshold = 5;
  const stage = e?.target.getStage();
  const position = stage && stage.getPointerPosition();
  const mousePos = useGetMousePos(stage);

  if (position && activeTool === ToolType.Point) {
    setShapes([
      ...shapes,
      { shape: ToolType.Point, left: mousePos[0], top: mousePos[1] },
    ]);
  } else if (
    position &&
    activeTool === ToolType.BoundingBox &&
    !tempBoundingBox &&
    !edit
  ) {
    setCreatingBoundingBox(true);
    setTempBoundingBox({
      shape: ToolType.BoundingBox,
      left: mousePos[0],
      top: mousePos[1],
    });
  } else if (activeTool === ToolType.Polygon) {
    const lastShape = shapes.length > 0 ? shapes[shapes.length - 1] : null;
    if (
      !lastShape ||
      lastShape.shape !== ToolType.Polygon ||
      lastShape.isFinished
    ) {
      setCreatingPolygon(true);
      setShapes([
        ...shapes,
        {
          shape: ToolType.Polygon,
          points: [{ left: mousePos[0], top: mousePos[1] }],
          isFinished: false,
        },
      ]);
    } else {
      const updatedShapes = shapes.slice();
      updatedShapes[updatedShapes.length - 1].points?.push({
        left: mousePos[0],
        top: mousePos[1],
      });
      setShapes(updatedShapes);
      if (lastShape?.points) {
        const firstPoint = lastShape.points[0];
        const distance = Math.sqrt(
          Math.pow(mousePos[0] - firstPoint?.left, 2) +
            Math.pow(mousePos[1] - firstPoint?.top, 2),
        );

        if (distance < distanceThreshold) {
          lastShape.points.pop();
          lastShape.isFinished = true;
          setCreatingPolygon(false);
        }

        if (lastShape.points.length > 2) {
          setPolygonLength(lastShape.points.length);
        }
      }
    }
  } else {
    if (
      tempBoundingBox &&
      (tempBoundingBox.width !== 0 || tempBoundingBox.height !== 0)
    ) {
      const newBoxes = [...shapes];
      newBoxes.push(tempBoundingBox);
      setShapes(newBoxes);
    }
    setTempBoundingBox(null);
    setCreatingBoundingBox(false);
  }
};
