import { SVGProps } from "react";

export const ArrowUpIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="10"
      viewBox="0 0 16 10"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.1211 0.661132C8.34375 0.661132 8.54297 0.746093 8.71875 0.916015L15.5215 7.87695C15.5977 7.95312 15.6563 8.03809 15.6973 8.13184C15.7383 8.23145 15.7588 8.33691 15.7588 8.44824C15.7588 8.60059 15.7236 8.73828 15.6533 8.86133C15.583 8.98437 15.4893 9.08105 15.3721 9.15137C15.249 9.22168 15.1113 9.25684 14.959 9.25684C14.7363 9.25684 14.5459 9.18066 14.3877 9.02832L7.65527 2.14648L8.5957 2.14648L1.86328 9.02832C1.69922 9.18066 1.50879 9.25683 1.29199 9.25683C1.13965 9.25683 1.00488 9.22168 0.887696 9.15137C0.764649 9.08105 0.667969 8.98437 0.597657 8.86133C0.527344 8.73828 0.492188 8.60058 0.492188 8.44824C0.492188 8.23144 0.57129 8.04394 0.729493 7.88574L7.53223 0.916015C7.6084 0.833983 7.69922 0.76953 7.80469 0.722655C7.9043 0.68164 8.00977 0.661132 8.1211 0.661132Z"
        fill="#FAFAFA"
      />
    </svg>
  );
};
