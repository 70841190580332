// Lib
import { FC } from "react";
import { Link, useLocation } from "react-router-dom";
// Utils
import { generateQueryUrl } from "ulits/generateQueryUrl";

import style from "./style.module.scss";

interface BreadCrumbsProps {
  placeholder?: string;
}

export const BreadCrumbs: FC<BreadCrumbsProps> = ({ placeholder }) => {
  const isAdmin = true;
  const location = useLocation();

  const { pathname, state } = location;

  const paths = pathname.split("/").filter(el => Boolean(el) && el !== "admin");

  const subpaths = paths.slice(1);

  const capitalize = (str: string) =>
    str.charAt(0).toUpperCase() + str.slice(1);

  const generateMainPath = () => {
    const query = state?.query ? generateQueryUrl("", state?.query) : "";

    return `${isAdmin && "/admin"}/${paths[0]}${query}`;
  };

  const generatePath = (str: string) => {
    const pathIndex = paths.findIndex(el => el === str);

    return `${isAdmin && "/admin"}/${paths.slice(0, pathIndex + 1).join("/")}`;
  };

  return (
    <div className={style.root}>
      <Link className={style.mainLink} to={generateMainPath()}>
        {capitalize(paths[0])}
      </Link>

      {subpaths.map((el, i, arr) => (
        <Link
          key={`${el}+${i}`}
          className={style.subLink}
          to={generatePath(el)}
        >
          {i === arr.length - 1 && placeholder ? `/${placeholder}` : `/${el}`}
        </Link>
      ))}
    </div>
  );
};
