import { Dispatch, SetStateAction } from "react";
import { Shape, ToolType } from "components/AnnotateTool/typed";

export const useDeleteUnfinishedPolygon = (
  setShapes: Dispatch<SetStateAction<Shape[]>>,
  setCreatingPolygon: Dispatch<SetStateAction<boolean>>,
) => {
  setShapes(prevShapes => {
    const updatedShapes = [...prevShapes];
    const lastShapeIndex = updatedShapes.length - 1;
    const lastShape = updatedShapes[lastShapeIndex];
    if (
      lastShape &&
      lastShape.shape === ToolType.Polygon &&
      !lastShape.isFinished
    ) {
      updatedShapes.splice(lastShapeIndex, 1);
      setCreatingPolygon(false);
    }
    return updatedShapes;
  });
};
