// Lib
import { FC } from "react";
import cn from "classnames";
// Types

import style from "./style.module.scss";
import { SessionType } from "types/sessions";

interface TypeProps {
  status: SessionType;
}

export const Type: FC<TypeProps> = ({ status }) => {
  const title = {
    [SessionType.ENTER]: "Enter the app",
  };

  const className = status.replaceAll(" ", "-");

  return (
    <div className={style.root}>
      <div className={cn(style.point, style[className])} />
      <span className={style.title}>{title[status]}</span>
    </div>
  );
};
