import cn from "classnames";

import { InfoIcon } from "icons";

import { Instruction } from "types/task";

import { generateUniqueId } from "ulits/generateUniqueId";

import style from "./style.module.scss";

export const handleMaxWidth = (maxWidth: number | undefined) => {
  const UIMaxWidth = 849;

  if (!maxWidth) {
    return UIMaxWidth;
  }

  return UIMaxWidth < maxWidth ? UIMaxWidth : maxWidth;
};

export const handleParseContent = (
  key: string,
  instruction: Instruction,
): JSX.Element | null => {
  if (!instruction) {
    return null;
  }

  if (key === "title") {
    return null;
  }

  const isNotes = key === "notes";

  return (
    <div key={generateUniqueId()} className={cn({ [style.note]: isNotes })}>
      <div className={style.taskTitleContainer}>
        {isNotes && <InfoIcon />}

        <p className={style.taskTitle}>{instruction.title}</p>
      </div>

      {!!instruction.description && (
        <p className={style.taskDescription}>{instruction.description}</p>
      )}

      {!!instruction?.bulletList?.length && (
        <div
          className={style.taskDescriptionBulletList}
          style={{ marginLeft: 0 }}
        >
          <ul>
            {instruction.bulletList.map(l => (
              <li key={generateUniqueId()}>{l.description}</li>
            ))}
          </ul>
        </div>
      )}

      {!!instruction?.orderList?.length && (
        <ol className={style.taskDescriptionList}>
          {instruction.orderList.map(c => (
            <li key={generateUniqueId()} className={style.taskDescription}>
              <span>{c.description}</span>

              {!!c?.bulletList?.length && (
                <ul>
                  {c.bulletList.map(l => (
                    <li key={generateUniqueId()}>{l.description}</li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ol>
      )}
    </div>
  );
};
