// Lib
import { useParams } from "react-router-dom";
// Api
import { useLogDetailsQuery } from "api/hooks/logs";
// Hooks
// Types
// Constants
// Components
import { AnnotateTool, BreadCrumbs } from "components";
import { useEffect, useState } from "react";
import { Shape } from "../../../components/AnnotateTool/typed";

export const LogDetails = () => {
  const { id } = useParams();
  const { data, isFetching } = useLogDetailsQuery({ id });
  const [shapes, setShapes] = useState<Shape[]>([]);
  const [image, setImage] = useState<string>("");
  useEffect(() => {
    if (data) {
      setImage(data?.data?.task?.imageUrl);
    }
  }, [data]);
  useEffect(() => {
    if (data?.data?.result) {
      // @ts-ignore
      setShapes(data?.data?.result);
    }
  }, [data]);
  return (
    <>
      <BreadCrumbs placeholder="log details" />
      <AnnotateTool
        imageUrl={image || ""}
        data={data?.data ? data?.data : null}
        shapes={shapes}
        setShapes={setShapes}
        admin={true}
      />
    </>
  );
};
