export enum SessionStatus {
  ACTIVE = "active",
  ENDED = "ended",
}

export enum SessionType {
  ENTER = "Enter the app",
}

export type SessionResponse = {
  client: { id: number; clientPublicIp: string; clientIp: string };
  createdAt: string;
  updatedAt: string;
  duration: number;
  id: number;
  status: SessionStatus;
  type: SessionType;
  startTime: string;
  endTime: string;
};
