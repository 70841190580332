import { LogDetails, Logs, Profile, Sessions } from "pages/Admin";
import { FC } from "react";

type Route = {
  path: string;
  Component: FC;
};

export const LOGIN = "/admin/login";

export const ADMIN_ROUTES: Record<string, Route> = {
  SESSIONS: {
    path: "/admin/sessions",
    Component: Sessions,
  },
  LOGS: {
    path: "/admin/logs",
    Component: Logs,
  },
  LOG_DETAILS: {
    path: "/admin/logs/:id",
    Component: LogDetails,
  },
  PROFILE: {
    path: "/admin/profile",
    Component: Profile,
  },
};
