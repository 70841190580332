export const endpoints = {
  signIn: "/api/v1/auth/login",
  passwordCheck: "/api/v1/admin/users/check-password",
  passwordChange: "/api/v1/admin/users/change-password",
  logs: "/api/v1/admin/tasks/logs",
  sessions: "/api/v1/admin/users/sessions/list",
  session: "/api/v1/users/sessions",
  tasks: {
    get: "/api/v1/users/get-task",
    post: "/api/v1/users/next-task",
  },
  task: "/api/v1/task",
};
