import React, { SVGProps } from "react";

export const ResetScaleIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.23313 14.0496L8.23312 14.0496L8.23626 14.0515C8.78099 14.374 9.38077 14.5342 10.0225 14.5342C10.6596 14.5342 11.2547 14.3733 11.7942 14.0496C12.3225 13.7326 12.746 13.3061 13.057 12.7763C13.3791 12.2318 13.5391 11.6324 13.5391 10.9912C13.5391 10.3551 13.3787 9.76092 13.0561 9.22213C12.7449 8.6927 12.321 8.26878 11.7916 7.9576C11.2528 7.63496 10.6586 7.47461 10.0225 7.47461C9.3812 7.47461 8.78179 7.63457 8.23734 7.95668C7.70755 8.26772 7.28105 8.69116 6.96407 9.21946C6.64037 9.75896 6.47949 10.354 6.47949 10.9912C6.47949 11.6329 6.63966 12.2327 6.9622 12.7774L6.96219 12.7774L6.96407 12.7805C7.28052 13.308 7.70571 13.7332 8.23313 14.0496Z"
        stroke="#BABABA"
        strokeWidth="1.25"
      />
      <path
        d="M2.20312 8.08984C1.73438 8.08984 1.5 7.84961 1.5 7.36914V5.21582C1.5 4.31348 1.72852 3.63672 2.18555 3.18555C2.64844 2.72852 3.33984 2.5 4.25977 2.5H6.4043C6.88477 2.5 7.125 2.73438 7.125 3.20312C7.125 3.67773 6.88477 3.91504 6.4043 3.91504H4.27734C3.83789 3.91504 3.50098 4.03223 3.2666 4.2666C3.03223 4.49512 2.91504 4.83789 2.91504 5.29492V7.36914C2.91504 7.84961 2.67773 8.08984 2.20312 8.08984ZM17.9971 8.08984C17.5283 8.08984 17.2939 7.84961 17.2939 7.36914V5.29492C17.2939 4.83789 17.1738 4.49512 16.9336 4.2666C16.6934 4.03223 16.3564 3.91504 15.9229 3.91504H13.7959C13.3213 3.91504 13.084 3.67773 13.084 3.20312C13.084 2.73438 13.3213 2.5 13.7959 2.5H15.9492C16.8691 2.5 17.5576 2.72852 18.0146 3.18555C18.4775 3.63672 18.709 4.31348 18.709 5.21582V7.36914C18.709 7.84961 18.4717 8.08984 17.9971 8.08984ZM4.25977 19.7002C3.33984 19.7002 2.64844 19.4717 2.18555 19.0146C1.72852 18.5635 1.5 17.8838 1.5 16.9756V14.8311C1.5 14.3506 1.73438 14.1104 2.20312 14.1104C2.67773 14.1104 2.91504 14.3506 2.91504 14.8311V16.9053C2.91504 17.3623 3.03223 17.7051 3.2666 17.9336C3.50098 18.168 3.83789 18.2852 4.27734 18.2852H6.4043C6.88477 18.2852 7.125 18.5225 7.125 18.9971C7.125 19.4658 6.88477 19.7002 6.4043 19.7002H4.25977ZM13.7959 19.7002C13.3213 19.7002 13.084 19.4658 13.084 18.9971C13.084 18.5225 13.3213 18.2852 13.7959 18.2852H15.9229C16.3564 18.2852 16.6934 18.168 16.9336 17.9336C17.1738 17.7051 17.2939 17.3623 17.2939 16.9053V14.8311C17.2939 14.3506 17.5283 14.1104 17.9971 14.1104C18.4717 14.1104 18.709 14.3506 18.709 14.8311V16.9756C18.709 17.8838 18.4775 18.5635 18.0146 19.0146C17.5576 19.4717 16.8691 19.7002 15.9492 19.7002H13.7959Z"
        fill="#BABABA"
      />
    </svg>
  );
};
