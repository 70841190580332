import { FC, useState } from "react";
import { Circle, Group } from "react-konva";
import { PointsProps } from "./typed";

const Points: FC<PointsProps> = ({
  box,
  index,
  handleCornerMouseDown,
  handleCornerDragMove,
  setDraggingCorner,
  setSelectedBoxIndex,
  admin,
}) => {
  const [selectedCorner, setSelectedCorner] = useState<string | null>(null);

  const cornerPositions =
    box.left && box.top && box.width !== undefined && box.height !== undefined
      ? [
          { x: box.left, y: box.top, position: "topLeft" },
          { x: box.left + box.width, y: box.top, position: "topRight" },
          { x: box.left, y: box.top + box.height, position: "bottomLeft" },
          {
            x: box.left + box.width,
            y: box.top + box.height,
            position: "bottomRight",
          },
        ]
      : [];

  return (
    <>
      {cornerPositions?.map((corner, cornerIndex) => (
        <Group
          key={cornerIndex}
          draggable={!admin}
          onMouseDown={() => {
            setSelectedCorner(corner.position);
            handleCornerMouseDown(index, corner.position);
          }}
          onDragMove={e => {
            handleCornerDragMove(e, index);
          }}
          onDragEnd={() => {
            setDraggingCorner(null);
            setSelectedBoxIndex(null);
            setSelectedCorner(null);
          }}
          onMouseOver={() => setSelectedCorner(corner.position)}
          onMouseOut={() => setSelectedCorner(null)}
          x={corner.x}
          y={corner.y}
        >
          <Circle
            width={
              selectedCorner && selectedCorner === corner.position ? 32 : 12
            }
            fill={
              selectedCorner && selectedCorner === corner.position
                ? "rgba(220, 26, 125, 0.5)"
                : "rgba(255, 255, 255, 1)"
            }
            stroke={
              selectedCorner === corner.position
                ? "#FAFAFA"
                : "rgba(255, 0, 131, 1)"
            }
            strokeWidth={selectedCorner === corner.position ? 1 : 2}
          />
          <Circle
            width={12}
            fill="rgba(250, 250, 250, 1)"
            stroke="rgba(255, 0, 131, 1)"
            strokeWidth={2}
            visible={selectedCorner === corner.position}
          />
        </Group>
      ))}
    </>
  );
};

export default Points;
