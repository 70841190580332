// Lib
import { FC } from "react";

import style from "./style.module.scss";

interface NoItemsContentProps {
  message: string;
  description?: string;
}

export const NoItemsContent: FC<NoItemsContentProps> = ({
  message,
  description,
}) => {
  return (
    <div className={style.root}>
      <p className={style.message}>{message}</p>
      <p className={style.description}>{description}</p>
    </div>
  );
};
