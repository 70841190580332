// Lib
import { useEffect, useState } from "react";
import { TablePaginationConfig } from "antd";
// Api
import { useLogsQuery } from "api/hooks/logs";
// Hooks
import { useTable } from "hooks";
// Types
import { FilterValue, SorterResult } from "antd/es/table/interface";
import { IBasePaginatedQuery } from "types/common";
import { LogDataResponse } from "types/logs";
// Components
import { Pagination, Table } from "components";

import style from "./style.module.scss";

import { columns } from "./config";

export const Logs = () => {
  const {
    page,
    limit,
    sortBy,
    sort,
    setPage,
    setLimit,
    handleSort,
    onTableRowClick,
  } = useTable();

  const { data, isFetching } = useLogsQuery({
    query: { page, limit, sortBy, sort },
  });

  const [tableData, setTableData] = useState<
    IBasePaginatedQuery<LogDataResponse[]> | undefined
  >(undefined);

  useEffect(() => {
    if (!data) return;

    setTableData(data);
  }, [data]);

  const handleTableChange = (
    _pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue | null>,
    sorter: SorterResult<LogDataResponse> | SorterResult<LogDataResponse>[],
  ) => {
    const sort = sorter as SorterResult<LogDataResponse>;
    handleSort(sort.columnKey, sort.order);
  };

  return (
    <>
      <h1 className={style.heading}>Logs</h1>

      <div className={style.tableWrapper}>
        <Table<LogDataResponse>
          loading={isFetching}
          columns={columns}
          totalCount={tableData?.meta?.total}
          totalCountPlaceholder="logs"
          dataSource={tableData?.data}
          empty={{ title: "No Logs found" }}
          limit={limit}
          setLimit={setLimit}
          onChange={handleTableChange}
          onRowClick={record => onTableRowClick(`/admin/logs/${record.id}`)}
        />
      </div>

      <Pagination
        page={page}
        setPage={setPage}
        totalItems={tableData?.meta?.total}
        limit={limit}
      />
    </>
  );
};
