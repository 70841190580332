import { FC, Fragment } from "react";
import {
  BoxToolIcon,
  MousePointer,
  PointIcon,
  PolygonToolIcon,
  SeparatorIcon,
} from "icons";
import { Button } from "components/Form";
import { ToolType } from "../AnnotateTool/typed";

import { HandlersBoxProps, IconType } from "./typed";
import style from "./style.module.scss";
import { Tooltip } from "../Tooltip";

export const HandlersBox: FC<HandlersBoxProps> = ({
  setActiveTool,
  activeIconIndex,
}) => {
  const handleIconClick = (type: ToolType | undefined) => {
    if (type) {
      setActiveTool(type);
    }
  };

  const icons: IconType[] = [
    { icon: MousePointer, type: ToolType.Edit, text: "cursor", hotkey: "Q" },
    { icon: SeparatorIcon },
    { icon: PointIcon, type: ToolType.Point, text: "point", hotkey: "W" },
    { icon: BoxToolIcon, type: ToolType.BoundingBox, text: "box", hotkey: "E" },
    {
      icon: PolygonToolIcon,
      type: ToolType.Polygon,
      text: "polygon",
      hotkey: "R",
    },
  ];

  return (
    <div className={style.handlersBox}>
      <div className={style.list}>
        {icons.map((item, index) => (
          <Fragment key={index}>
            {item.icon !== SeparatorIcon && (
              <Tooltip
                userTool={{
                  title: item.type ? `${item.text}` : "",
                  hotkey: item.hotkey ? item.hotkey : "",
                }}
                key={"button"}
              >
                <div style={{ display: "flex", width: "fit-content" }}>
                  <Button
                    isToolSelected={activeIconIndex === index}
                    key={index}
                    Icon={item.icon}
                    type={"tools-transparent"}
                    onClick={() => handleIconClick(item.type)}
                  />
                </div>
              </Tooltip>
            )}
            {item.icon === SeparatorIcon && (
              <div style={{ display: "flex", width: "fit-content" }}>
                <Button
                  isToolSelected={activeIconIndex === index}
                  key={index}
                  Icon={item.icon}
                  buttonClassName={style.separatedLine}
                  type={"tools-transparent"}
                  onClick={() => handleIconClick(item?.type)}
                />
              </div>
            )}
          </Fragment>
        ))}
      </div>
    </div>
  );
};
